.tag-override.p-tag {
  width: 160px !important;
  font-weight: 400;
  padding: 0.4rem 0.4rem;
  &.p-tag-info {
    background-color: var(--darkColor);
  }
  &.p-tag-warning {
    background-color: var(--lightColor);
    color: var(--whiteColor);
  }
}
