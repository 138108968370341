@for $index from 0 to 101 {
  .f-#{$index} {
    font-size: 0px + $index !important;
  }
  .p-#{$index} {
    padding: 0px + $index !important;
  }
  .pt-#{$index} {
    padding-top: 0px + $index !important;
  }
  .pb-#{$index} {
    padding-bottom: 0px + $index !important;
  }
  .pl-#{$index} {
    padding-left: 0px + $index !important;
  }
  .pr-#{$index} {
    padding-right: 0px + $index !important;
  }
  .m-#{$index} {
    margin: 0px + $index !important;
  }
  .ml-#{$index} {
    margin-left: 0px + $index !important;
  }
  .mr-#{$index} {
    margin-right: 0px + $index !important;
  }
  .mt-#{$index} {
    margin-top: 0px + $index !important;
  }
  .mb-#{$index} {
    margin-bottom: 0px + $index !important;
  }
}
