@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'assets/css/circle.css';
@import 'assets/css/helper';

@import "src/assets/css/common/card.scss";

body {
  height: 100vh !important;
  padding: 0 !important;
}

body,
textarea,
input[type='text'],
button {
  font-family: 'Poppins' !important;
}

* {
  scrollbar-width: thin;
}


body ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 6px;
}

body ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cacfd4;
}

/*
i.inverted.green.icon {
  color: var(--lightColor) !important;
}
*/

#document_header_show {
  color: var(--darkColor) !important;
}

#document_header_show i {
  color: var(--darkColor) !important;
}

.law-font {
  font-family: 'Poppins' !important;
}

.ms-button {
  background: var(--whiteColor) 0 0% no-repeat padding-box !important;
  box-shadow: 0 0 10px #67676729 !important;
  border-radius: var(--borderRadius) !important;
  font-size: 16px !important;
  padding: 10px 33px !important;
  width: -webkit-fill-available;
}

.ch-urlpath {
  color: #9094a3;
  font-weight: 300;
}

.ch-urlpath a {
  color: var(--darkColor) !important;
}

.law-form-addcustp .field {
  padding: 10px !important;
}

.law-form-addcustp label {
  font-size: 14px !important;
  color: rgb(85, 93, 100) !important;
  font-weight: 300 !important;
}

.law-page-head {
  color: var(--darkColor) !important;
  font-weight: 400 !important;
  font-style: normal !important;
  margin-top: 10px;
  font-size: 18px !important;
}

.download-button {
  background-color: var(--darkColor);
  color: var(--whiteColor);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.subtext {
  color: rgb(144, 148, 163);
  font-size: 16px;
  font-weight: 400;
}

.law-radio-container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 680px) {
  .law-radio-container {
    margin-top: 10px !important;
  }
}

/* Hide the browser's default radio button */
.law-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.law-radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.law-radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.law-radio-container input:checked ~ .checkmark {
  background-color: var(--darkColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.law-radio-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.law-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.law-radio-container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--whiteColor);
}

/*Add Customer End */
/*Customers Page Start*/
.law-pluscust-btn {
  border-radius: 50%;
  border: none;
  outline: none;
  font-size: 28px;
  width: 40px;
  height: 40px;
  line-height: 35px;
  background-color: var(--darkColor);
  color: var(--whiteColor);
  cursor: pointer;
  float: right;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.law-usriconcuspage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.min-width-25 {
  min-width: 25%;
}

.time-preference {
  display: flex !important;
  flex-direction: row;
}

.time-preference-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  min-width: 200px;
  margin-right: 30px !important;
  border-radius: 6px;
}

.time-preference-header-text {
  color: #0a143f;
  margin: 15px 0 !important;
  font-weight: 500;
  font-size: 16px !important;
}

.save-button {
  margin: 10px !important;
}

.day-name {
  padding: 8px 12px;
  font-size: 15px;
}

.day-row {
  display: flex;
  flex-direction: row;
  margin: 5px 0 !important;
}

.uplode-icon {
  font-size: 20px !important;
  color: var(--whiteColor) !important;
}

.chatbot-image {
  min-height: 100px;
  display: flex !important;
  align-items: flex-start !important;
}

.chatbot-uplode-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chatbot-uplode-botLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  height: 100%;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.select-color {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}

.time-preference-utc-text {
  margin-top: 35px !important;
  color: #9094a3;
  font-weight: 400;
}

.records-row {
  background-color: #edf2f6;
  border-radius: var(--borderRadius);
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 5px 0 10px;
  padding: 0 15px;
  color: #9094a3;
}

.min-width-35 {
  min-width: 35% !important;
}

.min-width-30 {
  min-width: 30% !important;
}

.min-width-20 {
  min-width: 20% !important;
}

.min-width-18 {
  min-width: 18% !important;
}

.min-width-15 {
  min-width: 15% !important;
}

.min-width-220px {
  min-width: 220px;

}

.ch-cust-table-paginsn {
  border: 0 solid silver;
  padding-left: 5px;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.ch-cust-table-paginsn div {
  float: left;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 10px;
  margin-bottom: 15px;
}

.ch-cust-table-paginsn .ch-page-bt {
  width: 75px;
  height: 38px;
  border-radius: var(--borderRadius);

  line-height: 38px;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--whiteColor);
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
}

.ch-cust-table-paginsn .ch-page-bt:last-child {
  margin-left: 15px;
}

.ch-cust-table-paginsn .ch-page-bt:hover {
  box-shadow: 0 0 7px rgba(0, 7, 36, 0.17);
}

.anchor-color {
  color: var(--darkColor);
}

.anchor-color:hover {
  color: var(--darkColor) !important;
}

.ch-cust-table-paginsn .ch-page-indx {
  width: 36px;
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
  height: 36px;
  border-radius: 22px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  font-weight: 100;
  margin-left: 15px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.ch-cust-table-paginsn .ch-page-indx:hover {
  box-shadow: 0 0 7px rgba(0, 7, 36, 0.17);
}

.ch-cust-table-paginsn .index-active {
  background-color: var(--darkColor);
  color: var(--whiteColor);
}

.blueFont {
  color: var(--darkColor) !important;
}

.button-color {
  color: var(--darkColor) !important;
  border: 1px solid var(--darkColor) !important;
  box-shadow: none !important;
  background-color: var(--whiteColor) !important;
}

.greyFont {
  color: #707070 !important;
}

.mg-top-1 {
  margin-top: 1rem !important;
  clear: both;
}

.float-right {
  float: right;
}

.textLeft {
  text-align: left !important;
}

/*shivam css end*/
.ch-card-column {
  min-width: 200px !important;
}

@media (max-width: 775px) {
  .ch-card-column {
    width: 100% !important;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--lightColor) !important;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.addText {
  text-decoration: underline;
  cursor: pointer;
}

.ch-optionaplusminus-red {
  margin-top: 8px !important;
  color: #ea5c5c;
  cursor: pointer;
}

.not-available {
  color: #ea5c5c !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/*Code For File PopUp*/
.filePopUp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(111, 128, 146, 0.7);
  display: none;
}

.file-header {
  background-color: #f6f8f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  color: var(--darkColor);
}

.file-header i {
  font-size: 20px;
}

.file-header h2 {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
}

.file-table {
  padding-left: 75px;
  font-weight: 400;
  font-size: 15px;
  width: 100%;
}

.file-table table {
  width: 100%;
  table-layout: fixed;
}

.status-button {
  padding: 10px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  border-radius: var(--borderRadius);
  background-color: rgba(00, 00, 00, 0.05);
  border: 1px solid #000000;
  color: #000000;
  outline: none;
  cursor: pointer;
}

.link-button {
  padding: 10px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  border-radius: var(--borderRadius);
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
  background-color: rgba(84, 150, 238, 0.05);
  outline: none;
  cursor: pointer;
}

.link-button:hover {
  color: var(--darkColor);
}

.file-main-div {
  width: 80vw;
  padding-top: 30px;
  padding-left: 75px;
  padding-right: 75px;
  background-image: url('/assets/images/headerBack.png');
  background-repeat: no-repeat;
  background-position: initial;
  background-size: 100% 700px;
  position: relative;
  z-index: 1010;
}

.back-left {
  height: 500px;
  background-image: url('/assets/images/bottomBack.png');
  width: 100%;
  transform: translateY(-250px);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position-x: -150px;
}

.back-right {
  height: 500px;
  background-image: url('/assets/images/bottomBack.png');
  width: 100%;
  transform: translateY(-250px) scaleX(-1);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position-x: -150px;
}

/*Calender Css Modification */
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--darkColor) !important;
}

.whitecolor {
  color: var(--whiteColor) !important;
}

/*Custom Radio Button*/
.custom-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheck {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  border: 1px solid #d5d7df;
}

/* On mouse-over, add a grey background color */
.custom-radio-container:hover input ~ .radioCheck {
  background-color: var(--whiteColor);
}

/* When the radio button is checked, add a blue background */
.custom-radio-container input:checked ~ .radioCheck {
  background-color: var(--whiteColor);
  border: 1px solid var(--darkColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheck:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-container input:checked ~ .radioCheck:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-container .radioCheck:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--darkColor);
}

.custom-header-time-saved {
  text-align: center;
  color: var(--darkColor) !important;
  font-weight: 400;
}

.d-flex {
  display: flex !important;
}

.color-indicator-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.color-indicator-sub {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.isSkipped {
  background-color: rgba(215, 214, 21, 0.57);
}

.isLoop {
  background-color: rgba(117, 117, 117, 0.2) !important;
}

.isHelp {
  background-color: rgba(109, 180, 215, 0.55);
}

.isContract {
  background-color: #ecf9ee !important;
  color: var(--darkColor);
}

.isWeb {
  background-color: rgba(63, 96, 215, 0.08) !important;
}

.isBaseNet {
  background-color: #e8f0ff !important;
}

.isBoth {
  background-color: rgba(227, 253, 255, 0.56) !important;
}

.isSyneton {
  background-color: #d7f0fb !important;
  color: var(--darkColor);
}

.div-margin {
  margin-left: 20px;
}

.contract-table-heading {
  width: 99%;
  padding: 0 10px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--darkColor);
  font-weight: bold;
  flex-direction: row;
}

.records {
  display: flex;
  flex-direction: column;
  min-height: 60px;
}

.text-color {
  color: #0a143f !important;
}

.records-row:hover {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  box-shadow: 0 0 6px #0000001a;
}

.white-row {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  padding: 0;
  min-height: 40px;
  margin: 7px 0 0;
}

.white-row .custom__button-hover {
  background: var(--cardBackGround);
  border-radius: 50%;
  margin-right: 5px;
}

.white-row:hover {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  box-shadow: none;
}

.records-header {
  display: flex;
  align-items: center;
  color: var(--darkColor) !important;
  font-weight: bold;
  flex-direction: row;
}

.records-header div {
  padding-left: 5px;
}

.records-header div:last-child {
  text-align: right;
  padding-right: 5px;
}

.records-list {
  display: flex;
  color: rgba(144, 148, 163, 1);
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  word-break: break-word;
}

.select-button {
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(19, 29, 73, 0.25);
  padding: 10px;
  color: #131d49;
  box-shadow: 0 0 3px rgba(0, 7, 36, 0.17);
  border-radius: 10px;
}

.subtext {
  color: rgb(144, 148, 163);
  font-size: 16px;
  font-weight: 400;
}

.select-button-main-div {
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.min-width-15 {
  min-width: 15% !important;
}

.min-width-20 {
  min-width: 20% !important;
}

.min-width-25 {
  min-width: 25% !important;
}

.min-width-35 {
  min-width: 35% !important;
}

.min-width-45 {
  min-width: 45% !important;
}

.min-width-50 {
  min-width: 50% !important;
}

.header-style {
  color: var(--lightColor) !important;
  font-weight: 600 !important;
}

.font-style {
  color: var(--lightColor) !important;
  font-size: 12px !important;
}

.structure-of-datepicker-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contract-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  width: auto !important;
}

.color-9094A3 {
  color: var(--lightColor) !important;
}

.transition-cus {
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.custom-entity {
  background-color: var(--darkColor);
  color: var(--whiteColor);
  border-radius: 2px;
  cursor: pointer;
}

.add_entity {
  color: #3da4fe !important;
  text-align: right;
  margin-top: 5px !important;
}

.add_entity:hover {
  color: #0078d7 !important;
  text-decoration: underline;
  cursor: pointer;
}

.ngxp__container {
  background-color: var(--whiteColor);
}

.c-p {
  cursor: pointer;
  outline: none !important;
}

.delete-times {
  margin-left: 10px;
  color: #d76363;
}

.option-item {
  padding: 8px 0;
  cursor: pointer;
  outline: none;
  font-weight: 400;
  color: #7d7d7d;

  span {
    margin-right: 5px;
  }
}

.option-item:hover {
  color: var(--darkColor);
}

.ngxp__container[x-placement^='top'] .ngxp__arrow {
  border-color: var(--whiteColor) transparent transparent transparent !important;
}

.ngxp__container[x-placement^='bottom'] .ngxp__arrow {
  border-color: transparent transparent var(--whiteColor) transparent !important;
}

.ngxp__container[x-placement^='right'] .ngxp__arrow {
  border-color: transparent var(--whiteColor) transparent transparent !important;
}

.ngxp__container[x-placement^='left'] .ngxp__arrow {
  border-color: transparent transparent transparent var(--whiteColor) !important;
}

/*Common class for table*/
.law-table-header {
  display: flex;
  align-items: center;
  color: var(--darkColor) !important;
  font-weight: bold;
  flex-direction: row;
}

.law-table-header div {
  padding-left: 0;
}

.law-table-header div:last-child {
  text-align: right;
}

.law-table-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

/*Width class with step of 5*/
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  max-width: max-content;
}

.w-fit-available {
  width: -webkit-fill-available;
}

.w-1rem {
  width: 1rem;
}

/*Flex common class*/
.just-end {
  justify-content: flex-end !important;
}

.just-center {
  justify-content: center !important;
}

.just-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.ml-auto {
  margin-left: auto;
}

.action-icon {
  border-radius: 50%;
  background-color: var(--whiteColor);
  border: 2px solid #edf2f6;
  padding: 3px 4px 2px 4px;
  cursor: pointer;
}

.disabled-navigation-btn {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.disabled-not-allow {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap {
  word-wrap: break-word;
}

.color-primary {
  color: var(--darkColor);
}

.color-secondary {
  color: var(--lightColor);
}

.flex-d-c {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-inline {
  display: inline-block;
}

.border-0 {
  border: 0 !important;
}

.cursor-p {
  cursor: pointer;
}

.word-break-wrap {
  word-break: break-word;
  word-wrap: break-word;
}

.fw-bold {
  font-weight: bold;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: var(--darkColor) !important;
}

.md-drppicker .btn {
  background-color: var(--darkColor) !important;
}

.blueBackGround {
  background-color: #206dff;
}

.greenBackground {
  background-color: #38c976;
}

.orangeBackGround {
  background-color: #eb3c00 !important;
}

.saffronBackGround {
  background-color: #ff9700 !important;
}

.redBackground {
  background-color: #fe5050;
}

.yellowBackGround {
  background-color: #f5c84f;
}

.blue-back {
  background-color: var(--darkColor) !important;
}

.green-back {
  background-color: #57b457;
}

.red-back {
  background-color: #d76363 !important;
}

.green-color {
  color: #57b457;
}

.red-color {
  color: #d76363 !important;
}

.yellow-color {
  color: #f5c84f;
}

.slight__dark__gray-color {
  color: #ced0d9 !important;
}

.meta {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.ch-place-head {
  font-size: 17px;
  font-weight: 300;
  margin-left: 8px;
  color: var(--darkColor);
}

.ch-childacordActive {
  padding: 0 !important;
}

.ch-childacordActive-i {
  background-color: var(--darkColor) !important;
}

.min-w-150 {
  min-width: 150px;
}

.input-width {
  width: 225px !important;
}

.h-100 {
  height: 100%;
}

.h-fit-content {
  height: fit-content;
}

.h-40 {
  height: 38px;
}

.h-5 {
  height: 5px;
}

.h-5rem {
  height: 5rem;
}

.h-130-px {
  height: 130px;
}

.btn-stripped {
  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
  );
  background-size: 30px 30px;
}

.active-stripes.btn-stripped {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.text-center {
  text-align: center;
}

.text__transform-capitalize {
  text-transform: capitalize;
}

.primary__background {
  background-color: var(--darkColor) !important;
  color: var(--whiteColor) !important;
}

.white__background {
  background-color: var(--whiteColor) !important;
}

.card__border-radius {
  border-radius: 7px;
}

.custom__button-hover {
  border-radius: 50%;
  background: var(--whiteColor) 0 0 no-repeat padding-box;
  opacity: 1;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--darkColor) !important;
  cursor: pointer;
  border: 2px solid #edf2f6;
  outline: none;
}

.comaplateButtonColor {
  color: #38c976 !important;
}

.custom__button-disabled {
  border-radius: 50%;
  background: var(--whiteColor) 0 0 no-repeat padding-box;
  opacity: 1;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797 !important;
  cursor: pointer;
  outline: none;
  border: none;
}

.shadow__none {
  box-shadow: none;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.gap-20 {
  gap: 20px;
}

.half__opacity {
  opacity: 0.5;
}

.f-26 {
  font-size: 26px !important;
}

.longText {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom__btn {
  background-color: var(--darkColor) !important;
  width: fit-content !important;
  font-weight: 400 !important;
  color: var(--whiteColor) !important;
  cursor: pointer;
  border: none;
}

.custom__btn:hover {
  background-color: var(--darkColor) !important;
}

.custom__btn:active {
  background-color: var(--darkColor) !important;
}

.secondary__btn {
  background-color: var(--lightColor) !important;
}

.secondary__btn:hover {
  background-color: var(--lightColor) !important;
}

.secondary__btn:active {
  background-color: var(--lightColor) !important;
}

.primary-color {
  color: var(--lightColor) !important;
}

// CSS FOR E-SIGNING | START
.custom__status {
  padding: 5px 15px !important;
  border-radius: 50px;
  color: var(--whiteColor);
}

.signed {
  background-color: #38c976;
}

.signed--color {
  color: #38c976;
}

.not__singed {
  background-color: #fe5050;
}

.sent_for_signing {
  background-color: #ff9700;
}

.sent_for_signing--color {
  color: #ff9700;
}

.partial__singed {
  background-color: #ff9700;
}

.partial__singed--color {
  color: #ff9700;
}

.contract__in_progress {
  background-color: #206dff;
}

.contract__in_progress__color {
  color: #206dff;
}

.error {
  background-color: #fe5050;
}

.error--color {
  color: #fe5050;
}

.status-not-sent,
.status-draft {
  background: var(--darkColor);
}

.status-sent {
  background: #7f7f7f;
}

.status-in-review,
.status-to-process {
  background: #ff9700;
}

.status-submitted,
.status-completed {
  background: #38c976;
}

.calculation-in-progress {
  background: #e68902;
}

.calculation-sent {
  background: #875204;
}

.calculation-approved {
  background: #7fdba7;
}

.calculation-rejected {
  background: #dd4b39;
}

.calculation-ready-for-filing {
  background: #00a7d3;
}

.calculation-TOW-ready {
  background: #0058aa;
}

.calculation-processed {
  background: #38c976;
}

.calculation-not-sent {
  background: var(--darkColor);
}

.calculation-done {
  background: #bf7304;
}

.calculation-processed {
  background: #38c976;
}

.calculation-locked {
  background: #ff1414;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

// CSS FOR E-SIGNING | END
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--whiteColor);
  background-color: var(--darkColor);
  padding: 10px 15px;
  font-size: 14px;
  outline: 0;
  border: 0;
  border-radius: var(--borderRadius);
  cursor: pointer;
}

button:disabled,
button[disabled],
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-secondary {
  @extend .button;
  background: var(--lightColor);
}

.circle__icon {
  background: var(--whiteColor);
  color: var(--lightColor);
  border-radius: 50%;
  padding: 5px;
}

.gap-10 {
  gap: 10px;
}

.d-none {
  display: none;
}

.slider-input {
  width: 100%;
  border: 1px solid var(--borderColor);
  font-size: 18px;
  font-weight: normal;
  border-radius: var(--borderRadius);
  padding: 15px;
  margin-bottom: 0;
  height: 80px;
  resize: none;
}

.slider {
  width: 30vw;
  min-width: 400px;

  .slider__header {
    display: flex;
    background: var(--darkColor);
    color: var(--whiteColor);
    font-size: 20px;
    font-weight: 600;
    padding: 20px 25px;

    .header__icon {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .slider__body {
    padding: 20px 25px;

    .row__header {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0 0 5px 0;
      font-size: 18px;
      color: #555d64;
      opacity: 0.5;
    }

    .remarks {
      margin-top: 10px;
      color: var(--darkColor);
    }

    .row__body {
      margin: 0;
      font-size: 16px;
      color: var(--darkColor);
    }
  }
}

.slider__header {
  display: flex;
  background: var(--darkColor);
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 600;
  padding: 20px 25px;

  .header__icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.slider__body {
  padding: 20px 25px;

  .row__header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 0 5px 0;
    font-size: 18px;
    color: #555d64;
    opacity: 0.5;
  }

  .remarks {
    margin-top: 10px;
    color: var(--darkColor);
  }

  .row__body {
    margin: 0;
    font-size: 16px;
    color: var(--darkColor);
  }
}

.no__record-found {
  width: -webkit-fill-available;
  margin-top: 20px;
  font-size: 20px;
  display: grid;
  place-items: center;
  font-weight: 600;
}

.tab__body-header {
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;
  color: var(--darkColor);
  font-size: 20px;
  font-weight: 700;
}

.w-available {
  width: -webkit-fill-available;
}

.dot {
  position: absolute;
  bottom: 1px;
  right: 10px;
  border: 1.5px solid var(--whiteColor);
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
}

mat-icon {
  @extend .c-p;
}

.ch-uploadtext {
  font-size: 17px;
  color: rgb(0, 7, 36);
  font-weight: 300;
  display: block;
  margin-bottom: 13px;
}

.document__upload-box {
  width: 100%;
  border: 2px dashed #ced0d9;
  border-radius: 10px;
  padding: 13px 0;
  cursor: pointer;
}

.document__upload-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  .material-icons {
    font-size: 50px !important;
    color: #ced0d9;
  }

  .upload-description {
    font-size: 18px;
    color: #9094a3;
  }
}

.custom__progressbar {
  height: 12px !important;
  border-radius: 7px !important;
}

.checklistDocsOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  border-radius: var(--borderRadius);
  padding: 20px;
  background-color: gray;
  color: var(--whiteColor);
  font-size: 20px;
  line-height: 1.5;
}

.blue__dot {
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #2b6dff;
}

.orange__dot {
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #f48f01;
}

.add__custom__variable-button {
  font-size: 15px;
  margin-left: 10px !important;
  color: var(--darkColor);
}

.nlpDetails {
  width: 300px;
  background-color: var(--whiteColor);
}

.choose_image-label {
  padding: 7px;
  background: #6f8092;
  color: var(--whiteColor);
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
}

.text-muted {
  opacity: 0.5;
  font-size: 10px;
}

.iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

// Todo: remove below class .z__index-loader when we add centralized loader functionality
// label drop-down z-index .ui.dimmer is 1000 so display loader upper level
.ui.dimmer {
  z-index: 1001 !important;
}

.ng2-dropdown-menu {
  div {
    z-index: 100 !important;
  }
}

.align-start {
  align-self: flex-start;
}

// class for adding asterisk sign when field is required
.field-required:after {
  content: '*';
  color: red;
}

// note: override scss import at last
#finalConfirmation {
  left: 0;

  .custom__btn {
    background-color: var(--button-background) !important;
    color: var(--button-text) !important;
    outline: 0 !important;
    border: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
  }
}

.reviewModal {
  .p-dialog-content {
    overflow: visible;
  }
}

.email__colors-div {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 10px !important;
  border-radius: var(--borderRadius);
}

.overflow-auto {
  overflow: auto;
}

.smile, .close {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.card__divider {
  border-top: 1px solid #b0b3bf;
  width: 37px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}


.email__colors-div {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 10px !important;
  border-radius: var(--borderRadius);
}

.content-separator {
  height: 1px;
  background-color: var(--borderColor);
}

.note-card {
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  padding: 10px;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
  color: var(--whiteColor);
}

.flex-none {
  flex: none !important;
}

.content-separator {
  height: 1px;
  background-color: var(--borderColor);
}

.note-card {
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  padding: 10px;
}

.additional__attachments-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  cursor: pointer;
  padding: 5px;
  max-width: 200px;
}

// contract-v2 form modal component all override style

ngx-extended-pdf-viewer button:focus, ngx-extended-pdf-viewer a:focus, ngx-extended-pdf-viewer input:focus, ngx-extended-pdf-viewer select:focus {
  border: 1px solid var(--darkColor) !important;
}

@import 'assets/css/override-scss/modalOverRide';
@import 'assets/css/override-scss/chipsOverRide';
@import 'src/assets/css/override-scss/primeng-override/progressbar';
