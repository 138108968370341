// START OVERRIDE CSS FOR (APP-CUSTOMER-REPORT & APP-FLOW-ERROR-REPORT)
.dialog-override {
  &.p-dialog {
    background-image: url("../../../images/report-backgropund.png");
    border-radius: 5px;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
  }

  &.p-dialog {
    .p-dialog-header {
      background: transparent;

      .p-dialog-title {
        font-weight: 500;
        font-size: 2rem;
        color: var(--whiteColor);
      }

      .p-dialog-header-icon {
        color: white;

        &:enabled {
          &:hover {
            background: none;
            color: white;
          }
        }
      }
    }

    .p-dialog-footer {
      background: transparent;
    }
  }
}

//This apply on dialog box close icon
.p-dialog {
  .p-dialog-header {
    .p-dialog-header-icon {
      &:focus {
        box-shadow: 0 0 0 0.2rem #5b8d97;
      }
    }
  }
}

//This apply on dialog box close icon at focus time
.p-link {
  &:focus {
    box-shadow: 0 0 0 0.2rem #5b8d97;
  }
}

.background-image-position-error-report {
  &.p-dialog {
    background-position: initial;
  }
}

.background-image-position-customer-report {
  &.p-dialog {
    background-position: bottom;
  }
}

.customer-report-content-height {
  max-height: 550px;
}

.customer-report-footer {
  color: black;
  font-weight: 500
}

// END OVERRIDE CSS FOR (APP-CUSTOMER-REPORT & APP-FLOW-ERROR-REPORT)

// START HIDE CLOSE ICON SCSS (APP-CUSTOMER-REPORT)
.hide-close-icon {
  &.p-dialog {
    .p-dialog-header {
      .p-dialog-header-icon {
        color: white;
        cursor: default;

        &:enabled {
          &:hover {
            background: none;
            color: white;
            cursor: default;
            box-shadow: none;
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// END HIDE ICON SCSS (APP-CUSTOMER-REPORT)

// START DIALOG CONTENT TEMPLATE OVERRIDE SCSS
.dialog-content-style {
  &.p-dialog-content {
    background: transparent;
    padding: 0 2rem 2rem 2rem;
  }
}

.dialog-content {
  padding: 20px;
  background: white;
  border-radius: 5px
}
// END DIALOG CONTENT TEMPLATE OVERRIDE SCSS

// START ADD DOCUMENT OVERRIDE SCSS
.dialog-content-padding {
  &.p-dialog {
    .p-dialog-content {
      padding: 1rem 1.5rem 1rem 1.5rem;
    }
  }
}
// END ADD DOCUMENT OVERRIDE SCSS

// START DIALOG HEADER TEMPLATE OVERRIDE SCSS
.dialog-header-style {
  &.p-dialog {
    .p-dialog-header {
      background: var(--darkColor);
      color: var(--whiteColor);
      padding: 1.2rem;
      font-size: 16px;
    }

    .p-dialog-header-icon {
      background: var(--whiteColor);
    }
  }
}

// END DIALOG HEADER TEMPLATE OVERRIDE SCSS

// START FILTER DIALOG OVERRIDE SCSS
.filter-dialog-header-button {
  &.p-button {
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid #e0e1e2;
    font-weight: 600;
  }

  &:enabled {
    &:hover {
      background-color: #cacbcd;
      background-image: none;
      color: rgba(0, 0, 0, .8);
      border: 1px solid #cacbcd;
    }
  }
}

// END FILTER DIALOG OVERRIDE SCSS


//START CONFIRM ALERT DIALOG OVERRIDE SCSS
.confirm__alert.p-dialog {
  .p-dialog-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background: var(--whiteColor);
    color: #495057;
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
}

//END CONFIRM ALERT DIALOG OVERRIDE SCSS

//START CALCULATION IFRAME DIALOG OVERRIDE SCSS
.dialog__mask-position {
  display: block !important;
  position: inherit !important;
  width: 100% !important;
  height: 100%;
}

.calculation-dialog.p-dialog {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  max-height: 100%;
  box-shadow: none;

  .p-dialog-header {
    padding: 0.5rem;

    .p-dialog-header-icon:last-child {
      display: none
    }
  }

  .p-dialog-content {
    padding: 0
  }

  &.p-dialog-maximized {
    position: absolute !important;
  }
}

//END CALCULATION IFRAME DIALOG OVERRIDE SCSS


.email__domain-dialog.p-dialog .p-dialog-header {
  background: var(--darkColor);
  color: var(--whiteColor);
}

.email__domain-dialog.p-dialog {
  width: 40vw;
}

.view__proposal-dialog {
  &.p-dialog {
    height: 100%;
    width: 83.33%
  }
}
