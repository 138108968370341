input, textarea {
  width: 100%;
}

.ql-container {
  font-family: Poppins;
  font-size: 14px;
}

.agenda__event-date__picker,
.audit__logs__full__day-range__picker.p-calendar {
  width: 100%;
}

.agenda__event-date__picker.p-calendar .p-inputtext {
  text-align: center;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.multi__select-width.p-multiselect {
  width: 100%;
}

.existing__customers-list.p-multiselect {
  width: 60%;
}

.existing__customers-list .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-multiselect .p-multiselect-panel {
  max-width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: pre-line;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #daeeec;
}

.error-message.p-inline-message.p-inline-message-error {
  margin-top: 5px;
}

.task__flow__select-width.p-dropdown {
  width: 30%;
}

.dashboard__language__select-width.p-dropdown {
  background: var(--darkColor);
  border: var(--darkColor);
}
.dashboard__language__select-width.p-dropdown .p-inputtext,
.dashboard__language__select-width.p-dropdown .p-dropdown-trigger {
  color: white;
}
.dashboard__language__select-width.p-dropdown .p-inputtext {
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.tco__select-width.p-dropdown {
  width: 20%;
}

.search__bar-width.p-dropdown,
.p-inputgroup .p-inputwrapper > .p-component {
  width: 100%;
  background: none;
  border: none;
}
.search__bar-width.p-dropdown .p-inputtext,
.p-inputgroup .p-inputwrapper > .p-component .p-inputtext {
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.p-inputgroup-addon {
  padding: 0;
}

.language__select-width.p-dropdown {
  border: none;
}
.language__select-width.p-dropdown .p-inputtext {
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.select-width.p-dropdown {
  width: 100%;
}

.p-dropdown-panel {
  width: min-content;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #c9e0e4;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
  border-color: #5b8d97;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primaryColor);
}

.p-multiselect:not(.p-disabled):hover {
  border-color: var(--lightColor);
}

app-contract-wise-customer .p-inputgroup {
  width: fit-content;
}

app-onboarding-company .p-dropdown {
  width: -webkit-fill-available;
}

.webflow-language-dropdown .p-dropdown .p-dropdown-label {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

app-agenda .agenda__event__full__day-range__picker, app-agenda .audit__logs__full__day-range__picker.p-calendar {
  width: 100%;
}
app-agenda .agenda__event__full__day-range__picker.p-calendar .p-inputtext {
  text-align: center;
}
app-agenda .fc-direction-ltr {
  height: calc(100vh - 135px);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button {
  background-color: var(--darkColor);
  border: 0;
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background-color: var(--darkColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  background-color: var(--darkColor);
  border: 0;
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  box-shadow: none !important;
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}
app-agenda .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .fc-v-event {
  background-color: var(--darkColor);
  border-color: var(--darkColor);
}
app-agenda .p-button:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
app-agenda .p-selectbutton .p-button.p-highlight, app-agenda .p-selectbutton .p-button.p-highlight:hover {
  background: var(--darkColor);
  border-color: var(--darkColor);
  color: var(--whiteColor);
}

.sidebar-menu .p-component {
  font-family: "Poppins" !important;
  font-size: 15px;
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-header > a,
.sidebar-menu .p-panelmenu .p-panelmenu-header.p-highlight > a,
.sidebar-menu .p-menuitem {
  background: var(--darkColor);
  border: none;
  font-weight: normal;
  cursor: pointer;
  color: var(--whiteColor);
  margin: 3px 5px 0;
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu.p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover,
.sidebar-menu .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover,
.sidebar-menu .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: var(--lightColor);
  border-radius: 5px;
  color: var(--whiteColor);
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover, .sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover, .sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: var(--lightColor);
  border-radius: 5px;
  color: var(--whiteColor);
}
.sidebar-menu .p-toggleable-content {
  width: 96%;
  margin-inline: auto;
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus,
.sidebar-menu .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:active,
.sidebar-menu .p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none;
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-content {
  padding: 0;
  border: 0;
  background: var(--darkColor);
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding-left: 30px;
}
.sidebar-menu .sidebar-panel__menu.p-panelmenu .p-menuitem-text {
  line-height: 1.5 !important;
}
.sidebar-menu .sidebar-panel__menu .p-panelmenu-header > a .p-menuitem-icon {
  font-size: 18px;
  padding-right: 4px;
}
.sidebar-menu .sidebar-panel__menu .p-menuitem-icon {
  font-size: 16px;
  padding-right: 4px;
}
.sidebar-menu .sidebar-panel__menu .p-menuitem-link-active {
  background: var(--lightColor) !important;
  border-radius: 6px !important;
}
.sidebar-menu .sidebar-panel__menu .p-panelmenu-icon {
  order: 1;
  margin-left: 0.5rem;
  margin-right: 0;
}
.sidebar-menu .sidebar-panel__menu .p-menuitem-text {
  flex-grow: 1;
}

.sidebar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text, .sidebar .p-menuitem-text, .sidebar .p-menuitem-icon {
  color: var(--whiteColor) !important;
}

.contract__form-breadcrumb.p-breadcrumb {
  border: none;
  border-radius: unset;
}

.p-component {
  font-family: Poppins, serif;
  font-size: 1rem;
}

.p-blockui-document.p-component-overlay {
  z-index: 9999;
}

.color__picker-input.p-inputtext {
  color: #707070;
  margin-left: 10px;
  padding: 0.09rem 0.4rem;
  width: 45%;
}

.quiz__builder__color__picker-input.p-inputtext {
  color: #707070;
  position: absolute;
  width: 6%;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.p-sidebar .p-sidebar-header {
  display: none;
}
.p-sidebar .p-sidebar-content {
  padding: 0;
}

.p-menubar .p-submenu-list {
  z-index: 100 !important;
  width: 100%;
}

.builder-step__select-width.p-dropdown {
  width: calc(100% - 35px) !important;
}

.builder-input-mapping__select-width.p-dropdown {
  width: calc(100% - 65px) !important;
}

.builder-api-input-mapping__select-width.p-dropdown {
  width: calc(100% - 70px) !important;
}

.builder-input-width {
  width: calc(100% - 30px) !important;
}

@media screen and (max-width: 1440px) {
  .p-panelmenu .p-panelmenu-header > a {
    padding: 0.7rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.4rem 1rem;
  }

  .sidebar-menu {
    max-height: calc(100vh - 95px) !important;
  }
}
.closed .ch-side-head .ch-head-image2 {
  margin-right: 0;
}
.closed .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon, .closed .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0;
  padding-right: 0;
}
.closed .p-menuitem-link {
  padding-left: 1rem !important;
  justify-content: center;
}
.closed .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
}
.closed .p-panelmenu-header-link, .closed .p-menuitem-link {
  position: relative;
}
.closed .p-panelmenu-header-link:hover .p-menuitem-text, .closed .p-menuitem-link:hover .p-menuitem-text {
  visibility: visible !important;
}
.closed .p-menuitem-text {
  display: block !important;
  position: absolute;
  left: 100px;
  top: 1px;
  text-align: left !important;
  background-color: var(--darkColor);
  border-radius: 4px !important;
  word-break: keep-all;
  white-space: nowrap;
  padding: 6px;
  visibility: hidden;
  z-index: 9999;
}

.navbar-panel__menu.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-left: 0.5rem;
  margin-right: 0;
  order: 1;
}

.p-datepicker table td > span.p-highlight {
  background: var(--lightColor);
  color: var(--whiteColor);
}
.p-datepicker table td > span:focus {
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: var(--darkColor);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover {
  color: var(--darkColor);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  background: none;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  background: none;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: none;
}

.task-due__date__picker.p-calendar .p-inputtext {
  visibility: hidden;
  height: 0;
  width: 0;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--darkColor);
  background: var(--darkColor);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--darkColor);
  box-shadow: 0 0 0 0.2rem #5b8d97;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--darkColor);
  background: var(--darkColor);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--darkColor);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--darkColor);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--darkColor);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}

.p-inputtext:enabled:hover {
  border-color: var(--darkColor);
}
.p-inputtext:enabled:focus {
  border-color: var(--lightColor);
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--darkColor);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--darkColor);
  border-color: var(--darkColor);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--darkColor);
  border-color: var(--darkColor);
}

.p-button {
  background: var(--darkColor);
  border-color: var(--darkColor);
}
.p-button:enabled:hover {
  background: var(--hoverColor);
  border: 1px solid var(--darkColor);
}
.p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-text:enabled:hover {
  background: none;
}
.p-button.p-button-secondary:enabled:focus {
  box-shadow: none;
}

.user_list_role_button .p-button.p-button-sm {
  cursor: default;
}

.account__settings-button .p-button.p-button-outlined {
  color: var(--whiteColor);
  background: transparent;
  cursor: pointer;
}
.account__settings-button .p-button.p-button-outlined:hover {
  border: 1px solid var(--lightColor);
  color: var(--whiteColor);
  background: transparent;
}

.icon-button.p-button {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.action-button-color.p-button.p-button-text {
  color: black;
}
.action-button-color.p-button.p-button-text:enabled:hover {
  color: black;
}

.custom__button-hover.p-button {
  background: #eff2f5;
  width: 34px;
  height: 34px;
  border: 1px solid #eff2f5;
  color: var(--whiteColor);
}
.custom__button-hover.p-button:enabled:hover {
  background: #eff2f5;
  border: 1px solid #eff2f5;
}

.table__action__custom__button-hover.p-button {
  background: var(--whiteColor);
  width: 34px;
  height: 34px;
  border: 2px solid #eff2f5;
  color: var(--darkColor);
  padding: 1px 5px;
}
.table__action__custom__button-hover.p-button:enabled:hover {
  background: var(--whiteColor);
  border: 2px solid #eff2f5;
  color: var(--darkColor);
}

.calculation-button.p-button {
  background: var(--whiteColor);
  border: 2px solid #eff2f5;
  color: var(--darkColor);
  padding: 4.5px 10px;
}
.calculation-button.p-button:enabled:hover {
  background: var(--whiteColor);
  border: 2px solid #eff2f5;
  color: var(--darkColor);
}

.status-button.p-button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #06323b;
  background: var(--borderColor) 0 0% no-repeat padding-box;
  border-radius: var(--borderRadius);
}
.status-button.p-button:enabled:hover {
  background: var(--borderColor) 0 0% no-repeat padding-box;
  color: #06323b;
  border: none;
}

.info_icon.p-button.p-button-icon-only.p-button-rounded {
  height: 28px;
  width: 28px;
}

.secondary__btn__color.p-button {
  border: 1px solid var(--lightColor);
  background: var(--lightColor);
  color: var(--whiteColor);
  cursor: pointer;
}
.secondary__btn__color.p-button:hover {
  border: 1px solid var(--lightColor);
  background: var(--lightColor);
  color: var(--whiteColor);
}

.bubbly-button.p-button {
  opacity: 0.8;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}
.bubbly-button.p-button:hover {
  background: var(--darkColor);
  opacity: 1;
  transform: scale(1.1);
}

.nlp__upload_button.p-button {
  background-color: #d3d3d3;
  color: var(--whiteColor);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.nlp__upload_button.p-button:hover {
  background-color: #d3d3d3;
  color: var(--whiteColor);
  border: none;
}

.panel__open__close-button.p-button {
  display: none;
  color: var(--darkColor);
  background: none;
  border: none;
}
.panel__open__close-button.p-button:enabled:hover {
  color: var(--lightColor);
  border: none;
  background: none;
}

@media screen and (max-width: 960px) {
  .panel__open__close-button.p-button {
    display: block;
  }
}
.feature-button.p-button {
  border-radius: var(--borderRadius);
  display: inline-block;
  font-size: 1em;
  padding: 0.5em 2em;
  margin: 8px 0;
  appearance: none;
  background-color: var(--darkColor);
  color: var(--whiteColor);
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  opacity: 0.8;
  width: 100%;
  box-shadow: none;
  word-break: break-word;
}
.feature-button.p-button:enabled:hover {
  transform: scale(1.1);
  opacity: 1 !important;
  background-color: var(--darkColor);
}

.ccf_process_button.p-button {
  border: none;
  background: var(--lightColor);
  color: var(--whiteColor);
  cursor: pointer;
}
.ccf_process_button.p-button:hover {
  border: none;
  background: var(--darkColor);
  color: var(--whiteColor);
}

.note__icon__button.p-button {
  padding: 5px;
}

.advanced__settings-accordion.p-accordion .p-accordion-header .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border: none;
  background: none;
}

.advanced__settings-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 1;
  font-size: 12px;
  margin-left: 3px;
}

.advanced__settings-accordion.p-accordion .p-accordion-content {
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
}

.personal__details-accordion.p-accordion .p-accordion-content {
  padding: 0;
  margin-top: 1rem;
}

.personal__details-accordion.p-accordion .p-accordion-content {
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border: none;
  background: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 1;
  font-size: 12px;
  margin-left: 3px;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: none;
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: none;
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: none;
  border: none;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.p-fileupload {
  border: 2px dotted #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: relative;
  min-height: 95px;
}
.p-fileupload p-messages .p-messages {
  margin-top: 70px;
}
.p-fileupload .p-progressbar {
  display: none;
}
.p-fileupload .p-fileupload-buttonbar {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}
.p-fileupload .p-fileupload-buttonbar .p-fileupload-choose {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.p-fileupload .p-fileupload-buttonbar .p-fileupload-choose .p-button-icon {
  font-size: 24px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: transparent;
  border: 0;
  color: var(--darkColor);
}
.p-fileupload .p-fileupload-buttonbar .p-button-label {
  font-size: 16px;
  font-weight: 400;
}
.p-fileupload .p-fileupload-content {
  background: transparent;
  border: 0;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding: 1rem;
}
.p-fileupload .p-fileupload-row {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.dialog-override.p-dialog {
  background-image: url("../../../images/report-backgropund.png");
  border-radius: 5px;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
}
.dialog-override.p-dialog .p-dialog-header {
  background: transparent;
}
.dialog-override.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 2rem;
  color: var(--whiteColor);
}
.dialog-override.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: white;
}
.dialog-override.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background: none;
  color: white;
}
.dialog-override.p-dialog .p-dialog-footer {
  background: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem #5b8d97;
}

.background-image-position-error-report.p-dialog {
  background-position: initial;
}

.background-image-position-customer-report.p-dialog {
  background-position: bottom;
}

.customer-report-content-height {
  max-height: 550px;
}

.customer-report-footer {
  color: black;
  font-weight: 500;
}

.hide-close-icon.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: white;
  cursor: default;
}
.hide-close-icon.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background: none;
  color: white;
  cursor: default;
  box-shadow: none;
}
.hide-close-icon.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.dialog-content-style.p-dialog-content {
  background: transparent;
  padding: 0 2rem 2rem 2rem;
}

.dialog-content {
  padding: 20px;
  background: white;
  border-radius: 5px;
}

.dialog-content-padding.p-dialog .p-dialog-content {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.dialog-header-style.p-dialog .p-dialog-header {
  background: var(--darkColor);
  color: var(--whiteColor);
  padding: 1.2rem;
  font-size: 16px;
}
.dialog-header-style.p-dialog .p-dialog-header-icon {
  background: var(--whiteColor);
}

.filter-dialog-header-button.p-button {
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid #e0e1e2;
  font-weight: 600;
}
.filter-dialog-header-button:enabled:hover {
  background-color: #cacbcd;
  background-image: none;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid #cacbcd;
}

.confirm__alert.p-dialog .p-dialog-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}
.confirm__alert.p-dialog .p-dialog-header {
  display: none;
}
.confirm__alert.p-dialog .p-dialog-content {
  background: var(--whiteColor);
  color: #495057;
  padding: 2rem 1.5rem 2rem 1.5rem;
}

.dialog__mask-position {
  display: block !important;
  position: inherit !important;
  width: 100% !important;
  height: 100%;
}

.calculation-dialog.p-dialog {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  max-height: 100%;
  box-shadow: none;
}
.calculation-dialog.p-dialog .p-dialog-header {
  padding: 0.5rem;
}
.calculation-dialog.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  display: none;
}
.calculation-dialog.p-dialog .p-dialog-content {
  padding: 0;
}
.calculation-dialog.p-dialog.p-dialog-maximized {
  position: absolute !important;
}

.email__domain-dialog.p-dialog .p-dialog-header {
  background: var(--darkColor);
  color: var(--whiteColor);
}

.email__domain-dialog.p-dialog {
  width: 40vw;
}

.view__proposal-dialog.p-dialog {
  height: 100%;
  width: 83.33%;
}

.dashboard-menubar.p-menubar {
  background: none;
  border: none;
  padding: 0;
}
.dashboard-menubar.p-menubar .pi {
  font-size: 20px;
}

.dashboard-menubar.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: none;
}

.dashboard-menubar.p-menubar .p-submenu-list {
  z-index: 1200 !important;
}

.delete__confirmation.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffffff;
  border: solid var(--darkColor);
  border-width: 0 0 0 6px;
  color: #000000;
}
.delete__confirmation.p-toast .p-toast-message .p-toast-icon-close {
  color: #000000;
}

app-chat-flow-step p-inputnumber,
app-chat-flow-step .p-inputnumber, app-contract-v2-step p-inputnumber,
app-contract-v2-step .p-inputnumber, app-web-chat-flow-step p-inputnumber,
app-web-chat-flow-step .p-inputnumber {
  width: 100%;
}

.p-tabview .p-tabview-nav-container {
  font-size: 1.2rem;
}
.p-tabview .p-tabview-panels {
  padding: 1rem 0 0 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--lightColor);
  border-color: var(--lightColor);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: var(--lightColor);
}
.p-tabview .p-tabview-nav-btn.p-link {
  color: var(--lightColor);
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  box-shadow: inset 0 0 0 0.2rem var(--lightColor);
}

app-calculation .calculation__tabs .p-tabview-nav-container {
  position: unset;
}
app-calculation .calculation__tabs .p-tabview-panels {
  height: calc(100% - 44px);
  min-height: unset;
}
app-calculation .calculation__tabs .p-tabview-panels .p-tabview-panel {
  overflow: hidden;
  height: 100%;
}

.company-details .p-tabview-nav-container {
  position: sticky;
  top: -15px;
  z-index: 7;
}
.company-details .p-tabview-panels {
  min-height: calc(100vh - 145px);
}

.slider .p-sidebar-header, .dashboard-sidebar.p-sidebar .p-sidebar-header {
  padding: 0;
}
.slider .p-sidebar-content, .dashboard-sidebar.p-sidebar .p-sidebar-content {
  padding: 0;
}

.dashboard-sidebar.p-sidebar {
  position: relative;
}
.dashboard-sidebar.p-sidebar .p-sidebar-content {
  position: static;
  overflow: hidden;
  height: 100%;
}
.dashboard-sidebar.p-sidebar .p-sidebar-content .sidebar {
  height: 100%;
}

.dashboard-sidebar.p-sidebar-left {
  width: fit-content;
}

@media screen and (max-width: 960px) {
  .dashboard-sidebar.p-sidebar {
    position: fixed;
  }
}
.stepper.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link {
  border-style: none none none none;
  background-color: var(--cardBackGround);
  box-shadow: 0 0 0 0;
}
.stepper.p-accordion .p-accordion-header .p-accordion-header-link {
  border-style: none none none none;
  background-color: var(--cardBackGround);
  box-shadow: 0 0 0 0;
}
.stepper.p-accordion .p-accordion-header .p-accordion-header-link::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #0000001f;
  z-index: 5;
}
.stepper.p-accordion .p-accordion-header .p-accordion-header-link:focus {
  box-shadow: none;
}
.stepper.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link::after {
  content: "";
  width: 2px;
  height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--cardBackGround);
  z-index: 5;
}
.stepper.p-accordion p-accordiontab:last-child .p-accordion-header .p-accordion-header-link::after {
  content: "";
  width: 2px;
  height: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--cardBackGround);
  z-index: 5;
}
.stepper.p-accordion p-accordiontab .p-accordion-content {
  border-left-color: #0000001f;
  border-style: none none none solid;
  background: transparent;
}
.stepper.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-left-color: transparent;
  border-style: none none none solid;
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  z-index: 6;
}

.p-tabview .p-tabview-nav-container {
  font-size: 1.2rem;
}
.p-tabview .p-tabview-panels {
  padding: 1rem 0 0 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--lightColor);
  border-color: var(--lightColor);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: var(--lightColor);
}
.p-tabview .p-tabview-nav-btn.p-link {
  color: var(--lightColor);
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  box-shadow: inset 0 0 0 0.2rem var(--lightColor);
}

app-calculation .calculation__tabs .p-tabview-nav-container {
  position: unset;
}
app-calculation .calculation__tabs .p-tabview-panels {
  height: calc(100% - 44px);
  min-height: unset;
}
app-calculation .calculation__tabs .p-tabview-panels .p-tabview-panel {
  overflow: hidden;
  height: 100%;
}

.company-details .p-tabview-nav-container {
  position: sticky;
  top: -15px;
  z-index: 7;
}
.company-details .p-tabview-panels {
  min-height: calc(100vh - 145px);
}

.p-timeline-event-opposite {
  display: none;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem 1rem 1rem;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0;
}
.p-datatable .p-datatable-header {
  border: none;
  background: none;
}
.p-datatable .p-paginator-top {
  border: none;
  padding-bottom: 1rem !important;
}
.p-datatable .p-sortable-column.p-highlight {
  color: var(--lightColor);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--lightColor);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--lightColor);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--lightColor);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon:hover {
  color: var(--lightColor);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #a3e2db;
}

.p-paginator {
  justify-content: flex-end !important;
  padding: 1rem 0.5rem 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #c1e2e9;
  border-color: #c1e2e9;
  color: var(--darkColor);
}

.submission-table.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 1rem 0.5rem;
}

.ready-to-submit-table .p-paginator-left-content {
  width: 50%;
}

.statistics-table.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem;
}
.statistics-table.p-datatable .p-datatable-tbody > tr > td {
  padding: 1.35rem 1rem;
}

.scroll-height.p-datatable-scrollable .p-datatable-tbody {
  max-height: 550px;
}

.import-tabwise-customers-table .p-datatable-footer {
  background: none;
  border: 0 none;
  padding: 0;
}

.import-valid-customers-table .p-datatable-footer {
  background: none;
  border: 0 none;
  padding: 0;
}

.email-setting-content-table.p-datatable .p-datatable-header {
  padding: 1rem 0;
}

.compare-tab-table.p-datatable .p-datatable-thead > tr > th, .attachment-tab-table.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem;
}
.compare-tab-table.p-datatable .p-datatable-tbody > tr > td, .attachment-tab-table.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
}

.p-breadcrumb {
  border: none;
  padding: 0;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  box-shadow: none;
}

.proposal__confirmation-card .p-card {
  border-radius: var(--borderRadius);
}
.proposal__confirmation-card .p-card .p-card-body .p-card-content {
  max-height: 71vh;
  overflow: auto;
}
.proposal__confirmation-card .p-card .p-card-body .p-card-content::-webkit-scrollbar {
  width: 0;
}

.p-speeddial {
  position: initial !important;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 3rem;
  height: 3rem;
  background: var(--lightColor);
  border-color: var(--lightColor);
}
.p-speeddial-button.p-button.p-button-icon-only:hover {
  background: var(--lightColor);
  border-color: var(--lightColor);
}

.p-speeddial-action {
  background: var(--darkColor);
  color: var(--whiteColor);
}
.p-speeddial-action:hover {
  background: var(--darkColor);
  color: var(--whiteColor);
}

.tag-override.p-tag {
  width: 160px !important;
  font-weight: 400;
  padding: 0.4rem 0.4rem;
}
.tag-override.p-tag.p-tag-info {
  background-color: var(--darkColor);
}
.tag-override.p-tag.p-tag-warning {
  background-color: var(--lightColor);
  color: var(--whiteColor);
}