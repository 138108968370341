@media screen and (max-width: 1440px) {
  .p-panelmenu .p-panelmenu-header > a{
    padding: 0.7rem ;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link{
    padding: 0.4rem 1rem ;
  }
  .sidebar-menu {
    max-height: calc(100vh - 95px) !important;
  }
}

//closed sidebar css
.closed{
  .ch-side-head {
    .ch-head-image2{
      margin-right: 0;
    }
  }
  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0;
    padding-right: 0;
  }
  .p-menuitem-link{
    padding-left: 1rem !important;
    justify-content: center;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1rem;
  }
  .p-panelmenu-header-link, .p-menuitem-link {
    position: relative;
    &:hover{
      .p-menuitem-text{
        visibility: visible !important;
      }
    }
  }
  .p-menuitem-text {
    display: block !important;
    position: absolute;
    left: 100px;
    top: 1px;
    text-align: left !important;
    background-color: var(--darkColor);
    border-radius: 4px !important;
    word-break: keep-all;
    white-space: nowrap;
    padding: 6px;
    visibility: hidden;
    z-index: 9999;
  }
}

.navbar-panel__menu.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-left: 0.5rem;
  margin-right: 0;
  order: 1;
}
