input, textarea {
  width: 100%;
}

.ql-container {
  font-family: Poppins;
  font-size: 14px;
}

.agenda__event-date__picker,
.audit__logs__full__day-range__picker.p-calendar {
  width: 100%;
}

.agenda__event-date__picker.p-calendar .p-inputtext {
  text-align: center;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.multi__select-width.p-multiselect {
  width: 100%;
}

.existing__customers-list.p-multiselect {
  width: 60%;
}

.existing__customers-list{
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding-top:2rem;
    padding-bottom:2rem;
  }
}

.p-multiselect .p-multiselect-panel {
  max-width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: pre-line;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #daeeec;
}

.error-message.p-inline-message.p-inline-message-error {
  margin-top: 5px;
}

.task__flow__select-width.p-dropdown {
  width: 30%;
}

.dashboard__language__select-width.p-dropdown {
  background: var(--darkColor);
  border: var(--darkColor);

  .p-inputtext,
  .p-dropdown-trigger {
    color: white;
  }

  .p-inputtext {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.tco__select-width.p-dropdown {
  width: 20%;
}

.search__bar-width.p-dropdown,
.p-inputgroup .p-inputwrapper > .p-component {
  width: 100%;
  background: none;
  border: none;

  .p-inputtext {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.p-inputgroup-addon {
  padding: 0;
}

.language__select-width {
  &.p-dropdown {
    border: none;

    .p-inputtext {
      padding: 0.5rem 0 0.5rem 0.5rem;
    }
  }
}

// START DROPDOWN OVERRIDE SCSS
.select-width {
  &.p-dropdown {
    width: 100%;
  }
}

.p-dropdown-panel {
  width: min-content;

  .p-dropdown-items {
    .p-dropdown-item {
      &.p-highlight {
        background: #c9e0e4;
      }
    }
  }
}

.p-dropdown {
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: 0 0 0 0.2rem #5b8d97;
      border-color: #5b8d97;
    }

    &:hover {
      border-color: var(--primaryColor);
    }
  }
}

// END DROPDOWN OVERRIDE SCSS

// START MULTISELECT OVERRIDE SCSS
.p-multiselect {
  &:not(.p-disabled):hover {
    border-color: var(--lightColor);
  }
}

// END MULTISELECT OVERRIDE SCSS

app-contract-wise-customer {
  .p-inputgroup {
    width: fit-content;
  }
}

app-onboarding-company {
  .p-dropdown {
    width: -webkit-fill-available;
  }
}

.webflow-language-dropdown{
  .p-dropdown{
    .p-dropdown-label{
      padding-top:9px !important;
      padding-bottom: 9px !important;
    }
  }
}

app-agenda {

  .agenda__event__full__day-range__picker, .audit__logs__full__day-range__picker.p-calendar {
    width: 100%;
  }

  .agenda__event__full__day-range__picker.p-calendar .p-inputtext {
    text-align: center;
  }

  .fc-direction-ltr {
    height: calc(100vh - 135px);
  }

  .fc {
    &.fc-theme-standard {
      .fc-toolbar {
        .fc-button {
          background-color: var(--darkColor);
          border: 0;

          &:enabled {
            &:hover {
              background-color: var(--darkColor);
            }
          }

          &:disabled {
            background-color: var(--darkColor);
            border: 0;
          }

          &:focus {
            box-shadow: none !important;
          }

          &.fc-timeGridWeek-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }

          &.fc-dayGridMonth-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }

          &.fc-timeGridDay-button {
            &:hover {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
              color: var(--whiteColor);
            }

            &.fc-button-active {
              &:hover {
                background-color: var(--darkColor);
                border-color: var(--darkColor);
                color: var(--whiteColor);
              }

              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }
        }
      }

      .fc-view-harness {
        .fc-event {
          &.fc-daygrid-block-event {
            background-color: var(--darkColor);
            border-color: var(--darkColor);
          }

          &.fc-daygrid-dot-event {
            .fc-daygrid-event-dot {
              background-color: var(--darkColor);
              border-color: var(--darkColor);
            }
          }
        }
      }
    }
  }

  .fc-v-event {
    background-color: var(--darkColor);
    border-color: var(--darkColor);
  }

  .p-button {
    &:focus {
      box-shadow: 0 0 0 0.2rem #5b8d97;
    }
  }

  .p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button.p-highlight:hover {
    background: var(--darkColor);
    border-color: var(--darkColor);
    color: var(--whiteColor);
  }
}

.sidebar-menu {
  .p-component {
    font-family: 'Poppins' !important;
    font-size: 15px;
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-header > a,
  .p-panelmenu .p-panelmenu-header.p-highlight > a,
  .p-menuitem {
    background: var(--darkColor);
    border: none;
    font-weight: normal;
    cursor: pointer;
    color: var(--whiteColor);
    margin: 3px 5px 0;
  }

  .sidebar-panel__menu.p-panelmenu.p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover,
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover,
  .p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover {
    background: var(--lightColor);
    border-radius: 5px;
    color: var(--whiteColor);
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover, .sidebar-panel__menu.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover, .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: var(--lightColor);
    border-radius: 5px;
    color: var(--whiteColor);
  }

  .p-toggleable-content {
    width: 96%;
    margin-inline: auto;
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus,
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:active,
  .p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none;
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-content {
    padding: 0;
    border: 0;
    background: var(--darkColor);
  }

  .sidebar-panel__menu.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding-left: 30px;
  }

  .sidebar-panel__menu.p-panelmenu .p-menuitem-text {
    line-height: 1.5 !important;
  }

  .sidebar-panel__menu {
    .p-panelmenu-header > a .p-menuitem-icon {
      font-size: 18px;
      padding-right: 4px;
    }

    .p-menuitem-icon {
      font-size: 16px;
      padding-right: 4px;
    }

    .p-menuitem-link-active {
      background: var(--lightColor) !important;
      border-radius: 6px !important;
    }

    .p-panelmenu-icon {
      order: 1;
      margin-left: 0.5rem;
      margin-right: 0;
    }

    .p-menuitem-text {
      flex-grow: 1;
    }
  }
}

.sidebar {
  .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text, .p-menuitem-text, .p-menuitem-icon {
    color: var(--whiteColor) !important;
  }
}

.contract__form-breadcrumb.p-breadcrumb {
  border: none;
  border-radius: unset;
}

.p-component {
  font-family: Poppins, serif;
  font-size: 1rem;
}

.p-blockui-document.p-component-overlay {
  z-index: 9999;
}

.color__picker-input.p-inputtext {
  color: rgb(112, 112, 112);
  margin-left: 10px;
  padding: 0.09rem 0.4rem;
  width: 45%;
}

.quiz__builder__color__picker-input.p-inputtext {
  color: #707070;
  position: absolute;
  width: 6%;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.p-sidebar {
  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0;
  }
}

.p-menubar {
  .p-submenu-list {
    z-index: 100 !important;
    width: 100%;
  }
}

.builder-step__select-width {
  &.p-dropdown {
    width: calc(100% - 35px) !important;
  }
}

.builder-input-mapping__select-width {
  &.p-dropdown {
    width: calc(100% - 65px) !important;
  }
}

.builder-api-input-mapping__select-width {
  &.p-dropdown {
    width: calc(100% - 70px) !important;
  }
}

.builder-input-width {
  width: calc(100% - 30px) !important;
}

@import "primeng-override/panelmenu";
@import "primeng-override/datepicker";
@import "primeng-override/checkbox";
@import "primeng-override/inputfileds";
@import "primeng-override/button";
@import "primeng-override/accordion";
@import 'primeng-override/fileUpload';
@import "primeng-override/dialog";
@import "primeng-override/menubar";
@import "primeng-override/toast";
@import "primeng-override/number-input";
@import "primeng-override/tab-view";
@import "primeng-override/dashboard";
@import "primeng-override/stepper";
@import "primeng-override/tab-view";
@import "primeng-override/timeline";
@import "primeng-override/table";
@import "primeng-override/breadcrumb";
@import "primeng-override/card";
@import "primeng-override/speed-dial";
@import "primeng-override/tag";
