.advanced__settings-accordion.p-accordion .p-accordion-header .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border: none;
  background: none;
}

.advanced__settings-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 1;
  font-size: 12px;
  margin-left: 3px;
}

.advanced__settings-accordion.p-accordion .p-accordion-content {
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
}

.personal__details-accordion.p-accordion .p-accordion-content {
  padding: 0;
  margin-top: 1rem;
}

.personal__details-accordion.p-accordion .p-accordion-content {
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .advanced__settings-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border: none;
  background: none;
}

.personal__details-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  order: 1;
  font-size: 12px;
  margin-left: 3px;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: none;
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: none;
  border: none;
}

.personal__details-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: none;
  border: none;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
