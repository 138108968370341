.slider,.dashboard-sidebar.p-sidebar {
  .p-sidebar-header {
    padding: 0;
  }
  .p-sidebar-content {
    padding: 0;
  }
}

.dashboard-sidebar.p-sidebar {
  position: relative;
  .p-sidebar-content {
    position: static;
    overflow: hidden;
    height: 100%;
    .sidebar {
      height: 100%;
    }
  }
}

.dashboard-sidebar.p-sidebar-left {
  width: fit-content;
}

@media screen and (max-width: 960px) {
  .dashboard-sidebar.p-sidebar {
    position: fixed;
  }
}
