.proposal__confirmation-card {
  .p-card {
    border-radius: var(--borderRadius);

    .p-card-body {
      .p-card-content {
        max-height: 71vh;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }
}
