app-common-chips, app-entity {
  .p-autocomplete, .p-component {
    .p-autocomplete-multiple-container, .p-chips-multiple-container{
      width: 100%;
      &:hover, &:focus, &:focus-within {
        border-color: #ced4da !important;
        box-shadow: 0 0 0 0 !important;
        outline: none !important;
      }
      .p-autocomplete-input-token, .p-chips-input-token {
        padding: 0 !important;
        input {
          border: 0 !important;
          padding: 10px !important;
          &:focus{
            box-shadow: 0 0 0 0 !important;
            outline: none !important;
          }
        }
      }
    }
  }
}
