@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'assets/css/circle.css';
.f-0 {
  font-size: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.f-1 {
  font-size: 1px !important;
}

.p-1 {
  padding: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.m-1 {
  margin: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.f-2 {
  font-size: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.m-2 {
  margin: 2px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.f-3 {
  font-size: 3px !important;
}

.p-3 {
  padding: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.m-3 {
  margin: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.f-4 {
  font-size: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.m-4 {
  margin: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.f-5 {
  font-size: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.f-6 {
  font-size: 6px !important;
}

.p-6 {
  padding: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.m-6 {
  margin: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.f-7 {
  font-size: 7px !important;
}

.p-7 {
  padding: 7px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.m-7 {
  margin: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.f-8 {
  font-size: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.m-8 {
  margin: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.p-9 {
  padding: 9px !important;
}

.pt-9 {
  padding-top: 9px !important;
}

.pb-9 {
  padding-bottom: 9px !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.pr-9 {
  padding-right: 9px !important;
}

.m-9 {
  margin: 9px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.p-11 {
  padding: 11px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.pr-11 {
  padding-right: 11px !important;
}

.m-11 {
  margin: 11px !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.mb-11 {
  margin-bottom: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.m-12 {
  margin: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.p-13 {
  padding: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.m-13 {
  margin: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.p-14 {
  padding: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.m-14 {
  margin: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.m-16 {
  margin: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.p-17 {
  padding: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.m-17 {
  margin: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.p-18 {
  padding: 18px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.m-18 {
  margin: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.p-19 {
  padding: 19px !important;
}

.pt-19 {
  padding-top: 19px !important;
}

.pb-19 {
  padding-bottom: 19px !important;
}

.pl-19 {
  padding-left: 19px !important;
}

.pr-19 {
  padding-right: 19px !important;
}

.m-19 {
  margin: 19px !important;
}

.ml-19 {
  margin-left: 19px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.p-21 {
  padding: 21px !important;
}

.pt-21 {
  padding-top: 21px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.pl-21 {
  padding-left: 21px !important;
}

.pr-21 {
  padding-right: 21px !important;
}

.m-21 {
  margin: 21px !important;
}

.ml-21 {
  margin-left: 21px !important;
}

.mr-21 {
  margin-right: 21px !important;
}

.mt-21 {
  margin-top: 21px !important;
}

.mb-21 {
  margin-bottom: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.p-22 {
  padding: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.m-22 {
  margin: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.p-23 {
  padding: 23px !important;
}

.pt-23 {
  padding-top: 23px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pl-23 {
  padding-left: 23px !important;
}

.pr-23 {
  padding-right: 23px !important;
}

.m-23 {
  margin: 23px !important;
}

.ml-23 {
  margin-left: 23px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.mt-23 {
  margin-top: 23px !important;
}

.mb-23 {
  margin-bottom: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.m-24 {
  margin: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.f-25 {
  font-size: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.f-26 {
  font-size: 26px !important;
}

.p-26 {
  padding: 26px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.m-26 {
  margin: 26px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.f-27 {
  font-size: 27px !important;
}

.p-27 {
  padding: 27px !important;
}

.pt-27 {
  padding-top: 27px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.pl-27 {
  padding-left: 27px !important;
}

.pr-27 {
  padding-right: 27px !important;
}

.m-27 {
  margin: 27px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.f-28 {
  font-size: 28px !important;
}

.p-28 {
  padding: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.m-28 {
  margin: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.f-29 {
  font-size: 29px !important;
}

.p-29 {
  padding: 29px !important;
}

.pt-29 {
  padding-top: 29px !important;
}

.pb-29 {
  padding-bottom: 29px !important;
}

.pl-29 {
  padding-left: 29px !important;
}

.pr-29 {
  padding-right: 29px !important;
}

.m-29 {
  margin: 29px !important;
}

.ml-29 {
  margin-left: 29px !important;
}

.mr-29 {
  margin-right: 29px !important;
}

.mt-29 {
  margin-top: 29px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.f-30 {
  font-size: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.f-31 {
  font-size: 31px !important;
}

.p-31 {
  padding: 31px !important;
}

.pt-31 {
  padding-top: 31px !important;
}

.pb-31 {
  padding-bottom: 31px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.pr-31 {
  padding-right: 31px !important;
}

.m-31 {
  margin: 31px !important;
}

.ml-31 {
  margin-left: 31px !important;
}

.mr-31 {
  margin-right: 31px !important;
}

.mt-31 {
  margin-top: 31px !important;
}

.mb-31 {
  margin-bottom: 31px !important;
}

.f-32 {
  font-size: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.m-32 {
  margin: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.f-33 {
  font-size: 33px !important;
}

.p-33 {
  padding: 33px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.pr-33 {
  padding-right: 33px !important;
}

.m-33 {
  margin: 33px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.f-34 {
  font-size: 34px !important;
}

.p-34 {
  padding: 34px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.m-34 {
  margin: 34px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.f-35 {
  font-size: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.f-36 {
  font-size: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.m-36 {
  margin: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.f-37 {
  font-size: 37px !important;
}

.p-37 {
  padding: 37px !important;
}

.pt-37 {
  padding-top: 37px !important;
}

.pb-37 {
  padding-bottom: 37px !important;
}

.pl-37 {
  padding-left: 37px !important;
}

.pr-37 {
  padding-right: 37px !important;
}

.m-37 {
  margin: 37px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.mr-37 {
  margin-right: 37px !important;
}

.mt-37 {
  margin-top: 37px !important;
}

.mb-37 {
  margin-bottom: 37px !important;
}

.f-38 {
  font-size: 38px !important;
}

.p-38 {
  padding: 38px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.m-38 {
  margin: 38px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.f-39 {
  font-size: 39px !important;
}

.p-39 {
  padding: 39px !important;
}

.pt-39 {
  padding-top: 39px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.pl-39 {
  padding-left: 39px !important;
}

.pr-39 {
  padding-right: 39px !important;
}

.m-39 {
  margin: 39px !important;
}

.ml-39 {
  margin-left: 39px !important;
}

.mr-39 {
  margin-right: 39px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mb-39 {
  margin-bottom: 39px !important;
}

.f-40 {
  font-size: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.f-41 {
  font-size: 41px !important;
}

.p-41 {
  padding: 41px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pb-41 {
  padding-bottom: 41px !important;
}

.pl-41 {
  padding-left: 41px !important;
}

.pr-41 {
  padding-right: 41px !important;
}

.m-41 {
  margin: 41px !important;
}

.ml-41 {
  margin-left: 41px !important;
}

.mr-41 {
  margin-right: 41px !important;
}

.mt-41 {
  margin-top: 41px !important;
}

.mb-41 {
  margin-bottom: 41px !important;
}

.f-42 {
  font-size: 42px !important;
}

.p-42 {
  padding: 42px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.m-42 {
  margin: 42px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.f-43 {
  font-size: 43px !important;
}

.p-43 {
  padding: 43px !important;
}

.pt-43 {
  padding-top: 43px !important;
}

.pb-43 {
  padding-bottom: 43px !important;
}

.pl-43 {
  padding-left: 43px !important;
}

.pr-43 {
  padding-right: 43px !important;
}

.m-43 {
  margin: 43px !important;
}

.ml-43 {
  margin-left: 43px !important;
}

.mr-43 {
  margin-right: 43px !important;
}

.mt-43 {
  margin-top: 43px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.f-44 {
  font-size: 44px !important;
}

.p-44 {
  padding: 44px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.m-44 {
  margin: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.f-45 {
  font-size: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.f-46 {
  font-size: 46px !important;
}

.p-46 {
  padding: 46px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.m-46 {
  margin: 46px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.f-47 {
  font-size: 47px !important;
}

.p-47 {
  padding: 47px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.pb-47 {
  padding-bottom: 47px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.pr-47 {
  padding-right: 47px !important;
}

.m-47 {
  margin: 47px !important;
}

.ml-47 {
  margin-left: 47px !important;
}

.mr-47 {
  margin-right: 47px !important;
}

.mt-47 {
  margin-top: 47px !important;
}

.mb-47 {
  margin-bottom: 47px !important;
}

.f-48 {
  font-size: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.m-48 {
  margin: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.f-49 {
  font-size: 49px !important;
}

.p-49 {
  padding: 49px !important;
}

.pt-49 {
  padding-top: 49px !important;
}

.pb-49 {
  padding-bottom: 49px !important;
}

.pl-49 {
  padding-left: 49px !important;
}

.pr-49 {
  padding-right: 49px !important;
}

.m-49 {
  margin: 49px !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.mr-49 {
  margin-right: 49px !important;
}

.mt-49 {
  margin-top: 49px !important;
}

.mb-49 {
  margin-bottom: 49px !important;
}

.f-50 {
  font-size: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.f-51 {
  font-size: 51px !important;
}

.p-51 {
  padding: 51px !important;
}

.pt-51 {
  padding-top: 51px !important;
}

.pb-51 {
  padding-bottom: 51px !important;
}

.pl-51 {
  padding-left: 51px !important;
}

.pr-51 {
  padding-right: 51px !important;
}

.m-51 {
  margin: 51px !important;
}

.ml-51 {
  margin-left: 51px !important;
}

.mr-51 {
  margin-right: 51px !important;
}

.mt-51 {
  margin-top: 51px !important;
}

.mb-51 {
  margin-bottom: 51px !important;
}

.f-52 {
  font-size: 52px !important;
}

.p-52 {
  padding: 52px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.m-52 {
  margin: 52px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.f-53 {
  font-size: 53px !important;
}

.p-53 {
  padding: 53px !important;
}

.pt-53 {
  padding-top: 53px !important;
}

.pb-53 {
  padding-bottom: 53px !important;
}

.pl-53 {
  padding-left: 53px !important;
}

.pr-53 {
  padding-right: 53px !important;
}

.m-53 {
  margin: 53px !important;
}

.ml-53 {
  margin-left: 53px !important;
}

.mr-53 {
  margin-right: 53px !important;
}

.mt-53 {
  margin-top: 53px !important;
}

.mb-53 {
  margin-bottom: 53px !important;
}

.f-54 {
  font-size: 54px !important;
}

.p-54 {
  padding: 54px !important;
}

.pt-54 {
  padding-top: 54px !important;
}

.pb-54 {
  padding-bottom: 54px !important;
}

.pl-54 {
  padding-left: 54px !important;
}

.pr-54 {
  padding-right: 54px !important;
}

.m-54 {
  margin: 54px !important;
}

.ml-54 {
  margin-left: 54px !important;
}

.mr-54 {
  margin-right: 54px !important;
}

.mt-54 {
  margin-top: 54px !important;
}

.mb-54 {
  margin-bottom: 54px !important;
}

.f-55 {
  font-size: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.m-55 {
  margin: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.f-56 {
  font-size: 56px !important;
}

.p-56 {
  padding: 56px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.m-56 {
  margin: 56px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.f-57 {
  font-size: 57px !important;
}

.p-57 {
  padding: 57px !important;
}

.pt-57 {
  padding-top: 57px !important;
}

.pb-57 {
  padding-bottom: 57px !important;
}

.pl-57 {
  padding-left: 57px !important;
}

.pr-57 {
  padding-right: 57px !important;
}

.m-57 {
  margin: 57px !important;
}

.ml-57 {
  margin-left: 57px !important;
}

.mr-57 {
  margin-right: 57px !important;
}

.mt-57 {
  margin-top: 57px !important;
}

.mb-57 {
  margin-bottom: 57px !important;
}

.f-58 {
  font-size: 58px !important;
}

.p-58 {
  padding: 58px !important;
}

.pt-58 {
  padding-top: 58px !important;
}

.pb-58 {
  padding-bottom: 58px !important;
}

.pl-58 {
  padding-left: 58px !important;
}

.pr-58 {
  padding-right: 58px !important;
}

.m-58 {
  margin: 58px !important;
}

.ml-58 {
  margin-left: 58px !important;
}

.mr-58 {
  margin-right: 58px !important;
}

.mt-58 {
  margin-top: 58px !important;
}

.mb-58 {
  margin-bottom: 58px !important;
}

.f-59 {
  font-size: 59px !important;
}

.p-59 {
  padding: 59px !important;
}

.pt-59 {
  padding-top: 59px !important;
}

.pb-59 {
  padding-bottom: 59px !important;
}

.pl-59 {
  padding-left: 59px !important;
}

.pr-59 {
  padding-right: 59px !important;
}

.m-59 {
  margin: 59px !important;
}

.ml-59 {
  margin-left: 59px !important;
}

.mr-59 {
  margin-right: 59px !important;
}

.mt-59 {
  margin-top: 59px !important;
}

.mb-59 {
  margin-bottom: 59px !important;
}

.f-60 {
  font-size: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.m-60 {
  margin: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.f-61 {
  font-size: 61px !important;
}

.p-61 {
  padding: 61px !important;
}

.pt-61 {
  padding-top: 61px !important;
}

.pb-61 {
  padding-bottom: 61px !important;
}

.pl-61 {
  padding-left: 61px !important;
}

.pr-61 {
  padding-right: 61px !important;
}

.m-61 {
  margin: 61px !important;
}

.ml-61 {
  margin-left: 61px !important;
}

.mr-61 {
  margin-right: 61px !important;
}

.mt-61 {
  margin-top: 61px !important;
}

.mb-61 {
  margin-bottom: 61px !important;
}

.f-62 {
  font-size: 62px !important;
}

.p-62 {
  padding: 62px !important;
}

.pt-62 {
  padding-top: 62px !important;
}

.pb-62 {
  padding-bottom: 62px !important;
}

.pl-62 {
  padding-left: 62px !important;
}

.pr-62 {
  padding-right: 62px !important;
}

.m-62 {
  margin: 62px !important;
}

.ml-62 {
  margin-left: 62px !important;
}

.mr-62 {
  margin-right: 62px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.f-63 {
  font-size: 63px !important;
}

.p-63 {
  padding: 63px !important;
}

.pt-63 {
  padding-top: 63px !important;
}

.pb-63 {
  padding-bottom: 63px !important;
}

.pl-63 {
  padding-left: 63px !important;
}

.pr-63 {
  padding-right: 63px !important;
}

.m-63 {
  margin: 63px !important;
}

.ml-63 {
  margin-left: 63px !important;
}

.mr-63 {
  margin-right: 63px !important;
}

.mt-63 {
  margin-top: 63px !important;
}

.mb-63 {
  margin-bottom: 63px !important;
}

.f-64 {
  font-size: 64px !important;
}

.p-64 {
  padding: 64px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.m-64 {
  margin: 64px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.f-65 {
  font-size: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.m-65 {
  margin: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.f-66 {
  font-size: 66px !important;
}

.p-66 {
  padding: 66px !important;
}

.pt-66 {
  padding-top: 66px !important;
}

.pb-66 {
  padding-bottom: 66px !important;
}

.pl-66 {
  padding-left: 66px !important;
}

.pr-66 {
  padding-right: 66px !important;
}

.m-66 {
  margin: 66px !important;
}

.ml-66 {
  margin-left: 66px !important;
}

.mr-66 {
  margin-right: 66px !important;
}

.mt-66 {
  margin-top: 66px !important;
}

.mb-66 {
  margin-bottom: 66px !important;
}

.f-67 {
  font-size: 67px !important;
}

.p-67 {
  padding: 67px !important;
}

.pt-67 {
  padding-top: 67px !important;
}

.pb-67 {
  padding-bottom: 67px !important;
}

.pl-67 {
  padding-left: 67px !important;
}

.pr-67 {
  padding-right: 67px !important;
}

.m-67 {
  margin: 67px !important;
}

.ml-67 {
  margin-left: 67px !important;
}

.mr-67 {
  margin-right: 67px !important;
}

.mt-67 {
  margin-top: 67px !important;
}

.mb-67 {
  margin-bottom: 67px !important;
}

.f-68 {
  font-size: 68px !important;
}

.p-68 {
  padding: 68px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pl-68 {
  padding-left: 68px !important;
}

.pr-68 {
  padding-right: 68px !important;
}

.m-68 {
  margin: 68px !important;
}

.ml-68 {
  margin-left: 68px !important;
}

.mr-68 {
  margin-right: 68px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.f-69 {
  font-size: 69px !important;
}

.p-69 {
  padding: 69px !important;
}

.pt-69 {
  padding-top: 69px !important;
}

.pb-69 {
  padding-bottom: 69px !important;
}

.pl-69 {
  padding-left: 69px !important;
}

.pr-69 {
  padding-right: 69px !important;
}

.m-69 {
  margin: 69px !important;
}

.ml-69 {
  margin-left: 69px !important;
}

.mr-69 {
  margin-right: 69px !important;
}

.mt-69 {
  margin-top: 69px !important;
}

.mb-69 {
  margin-bottom: 69px !important;
}

.f-70 {
  font-size: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.m-70 {
  margin: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.f-71 {
  font-size: 71px !important;
}

.p-71 {
  padding: 71px !important;
}

.pt-71 {
  padding-top: 71px !important;
}

.pb-71 {
  padding-bottom: 71px !important;
}

.pl-71 {
  padding-left: 71px !important;
}

.pr-71 {
  padding-right: 71px !important;
}

.m-71 {
  margin: 71px !important;
}

.ml-71 {
  margin-left: 71px !important;
}

.mr-71 {
  margin-right: 71px !important;
}

.mt-71 {
  margin-top: 71px !important;
}

.mb-71 {
  margin-bottom: 71px !important;
}

.f-72 {
  font-size: 72px !important;
}

.p-72 {
  padding: 72px !important;
}

.pt-72 {
  padding-top: 72px !important;
}

.pb-72 {
  padding-bottom: 72px !important;
}

.pl-72 {
  padding-left: 72px !important;
}

.pr-72 {
  padding-right: 72px !important;
}

.m-72 {
  margin: 72px !important;
}

.ml-72 {
  margin-left: 72px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.f-73 {
  font-size: 73px !important;
}

.p-73 {
  padding: 73px !important;
}

.pt-73 {
  padding-top: 73px !important;
}

.pb-73 {
  padding-bottom: 73px !important;
}

.pl-73 {
  padding-left: 73px !important;
}

.pr-73 {
  padding-right: 73px !important;
}

.m-73 {
  margin: 73px !important;
}

.ml-73 {
  margin-left: 73px !important;
}

.mr-73 {
  margin-right: 73px !important;
}

.mt-73 {
  margin-top: 73px !important;
}

.mb-73 {
  margin-bottom: 73px !important;
}

.f-74 {
  font-size: 74px !important;
}

.p-74 {
  padding: 74px !important;
}

.pt-74 {
  padding-top: 74px !important;
}

.pb-74 {
  padding-bottom: 74px !important;
}

.pl-74 {
  padding-left: 74px !important;
}

.pr-74 {
  padding-right: 74px !important;
}

.m-74 {
  margin: 74px !important;
}

.ml-74 {
  margin-left: 74px !important;
}

.mr-74 {
  margin-right: 74px !important;
}

.mt-74 {
  margin-top: 74px !important;
}

.mb-74 {
  margin-bottom: 74px !important;
}

.f-75 {
  font-size: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.m-75 {
  margin: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.f-76 {
  font-size: 76px !important;
}

.p-76 {
  padding: 76px !important;
}

.pt-76 {
  padding-top: 76px !important;
}

.pb-76 {
  padding-bottom: 76px !important;
}

.pl-76 {
  padding-left: 76px !important;
}

.pr-76 {
  padding-right: 76px !important;
}

.m-76 {
  margin: 76px !important;
}

.ml-76 {
  margin-left: 76px !important;
}

.mr-76 {
  margin-right: 76px !important;
}

.mt-76 {
  margin-top: 76px !important;
}

.mb-76 {
  margin-bottom: 76px !important;
}

.f-77 {
  font-size: 77px !important;
}

.p-77 {
  padding: 77px !important;
}

.pt-77 {
  padding-top: 77px !important;
}

.pb-77 {
  padding-bottom: 77px !important;
}

.pl-77 {
  padding-left: 77px !important;
}

.pr-77 {
  padding-right: 77px !important;
}

.m-77 {
  margin: 77px !important;
}

.ml-77 {
  margin-left: 77px !important;
}

.mr-77 {
  margin-right: 77px !important;
}

.mt-77 {
  margin-top: 77px !important;
}

.mb-77 {
  margin-bottom: 77px !important;
}

.f-78 {
  font-size: 78px !important;
}

.p-78 {
  padding: 78px !important;
}

.pt-78 {
  padding-top: 78px !important;
}

.pb-78 {
  padding-bottom: 78px !important;
}

.pl-78 {
  padding-left: 78px !important;
}

.pr-78 {
  padding-right: 78px !important;
}

.m-78 {
  margin: 78px !important;
}

.ml-78 {
  margin-left: 78px !important;
}

.mr-78 {
  margin-right: 78px !important;
}

.mt-78 {
  margin-top: 78px !important;
}

.mb-78 {
  margin-bottom: 78px !important;
}

.f-79 {
  font-size: 79px !important;
}

.p-79 {
  padding: 79px !important;
}

.pt-79 {
  padding-top: 79px !important;
}

.pb-79 {
  padding-bottom: 79px !important;
}

.pl-79 {
  padding-left: 79px !important;
}

.pr-79 {
  padding-right: 79px !important;
}

.m-79 {
  margin: 79px !important;
}

.ml-79 {
  margin-left: 79px !important;
}

.mr-79 {
  margin-right: 79px !important;
}

.mt-79 {
  margin-top: 79px !important;
}

.mb-79 {
  margin-bottom: 79px !important;
}

.f-80 {
  font-size: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.m-80 {
  margin: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.f-81 {
  font-size: 81px !important;
}

.p-81 {
  padding: 81px !important;
}

.pt-81 {
  padding-top: 81px !important;
}

.pb-81 {
  padding-bottom: 81px !important;
}

.pl-81 {
  padding-left: 81px !important;
}

.pr-81 {
  padding-right: 81px !important;
}

.m-81 {
  margin: 81px !important;
}

.ml-81 {
  margin-left: 81px !important;
}

.mr-81 {
  margin-right: 81px !important;
}

.mt-81 {
  margin-top: 81px !important;
}

.mb-81 {
  margin-bottom: 81px !important;
}

.f-82 {
  font-size: 82px !important;
}

.p-82 {
  padding: 82px !important;
}

.pt-82 {
  padding-top: 82px !important;
}

.pb-82 {
  padding-bottom: 82px !important;
}

.pl-82 {
  padding-left: 82px !important;
}

.pr-82 {
  padding-right: 82px !important;
}

.m-82 {
  margin: 82px !important;
}

.ml-82 {
  margin-left: 82px !important;
}

.mr-82 {
  margin-right: 82px !important;
}

.mt-82 {
  margin-top: 82px !important;
}

.mb-82 {
  margin-bottom: 82px !important;
}

.f-83 {
  font-size: 83px !important;
}

.p-83 {
  padding: 83px !important;
}

.pt-83 {
  padding-top: 83px !important;
}

.pb-83 {
  padding-bottom: 83px !important;
}

.pl-83 {
  padding-left: 83px !important;
}

.pr-83 {
  padding-right: 83px !important;
}

.m-83 {
  margin: 83px !important;
}

.ml-83 {
  margin-left: 83px !important;
}

.mr-83 {
  margin-right: 83px !important;
}

.mt-83 {
  margin-top: 83px !important;
}

.mb-83 {
  margin-bottom: 83px !important;
}

.f-84 {
  font-size: 84px !important;
}

.p-84 {
  padding: 84px !important;
}

.pt-84 {
  padding-top: 84px !important;
}

.pb-84 {
  padding-bottom: 84px !important;
}

.pl-84 {
  padding-left: 84px !important;
}

.pr-84 {
  padding-right: 84px !important;
}

.m-84 {
  margin: 84px !important;
}

.ml-84 {
  margin-left: 84px !important;
}

.mr-84 {
  margin-right: 84px !important;
}

.mt-84 {
  margin-top: 84px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.f-85 {
  font-size: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.m-85 {
  margin: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.f-86 {
  font-size: 86px !important;
}

.p-86 {
  padding: 86px !important;
}

.pt-86 {
  padding-top: 86px !important;
}

.pb-86 {
  padding-bottom: 86px !important;
}

.pl-86 {
  padding-left: 86px !important;
}

.pr-86 {
  padding-right: 86px !important;
}

.m-86 {
  margin: 86px !important;
}

.ml-86 {
  margin-left: 86px !important;
}

.mr-86 {
  margin-right: 86px !important;
}

.mt-86 {
  margin-top: 86px !important;
}

.mb-86 {
  margin-bottom: 86px !important;
}

.f-87 {
  font-size: 87px !important;
}

.p-87 {
  padding: 87px !important;
}

.pt-87 {
  padding-top: 87px !important;
}

.pb-87 {
  padding-bottom: 87px !important;
}

.pl-87 {
  padding-left: 87px !important;
}

.pr-87 {
  padding-right: 87px !important;
}

.m-87 {
  margin: 87px !important;
}

.ml-87 {
  margin-left: 87px !important;
}

.mr-87 {
  margin-right: 87px !important;
}

.mt-87 {
  margin-top: 87px !important;
}

.mb-87 {
  margin-bottom: 87px !important;
}

.f-88 {
  font-size: 88px !important;
}

.p-88 {
  padding: 88px !important;
}

.pt-88 {
  padding-top: 88px !important;
}

.pb-88 {
  padding-bottom: 88px !important;
}

.pl-88 {
  padding-left: 88px !important;
}

.pr-88 {
  padding-right: 88px !important;
}

.m-88 {
  margin: 88px !important;
}

.ml-88 {
  margin-left: 88px !important;
}

.mr-88 {
  margin-right: 88px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.mb-88 {
  margin-bottom: 88px !important;
}

.f-89 {
  font-size: 89px !important;
}

.p-89 {
  padding: 89px !important;
}

.pt-89 {
  padding-top: 89px !important;
}

.pb-89 {
  padding-bottom: 89px !important;
}

.pl-89 {
  padding-left: 89px !important;
}

.pr-89 {
  padding-right: 89px !important;
}

.m-89 {
  margin: 89px !important;
}

.ml-89 {
  margin-left: 89px !important;
}

.mr-89 {
  margin-right: 89px !important;
}

.mt-89 {
  margin-top: 89px !important;
}

.mb-89 {
  margin-bottom: 89px !important;
}

.f-90 {
  font-size: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.m-90 {
  margin: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.f-91 {
  font-size: 91px !important;
}

.p-91 {
  padding: 91px !important;
}

.pt-91 {
  padding-top: 91px !important;
}

.pb-91 {
  padding-bottom: 91px !important;
}

.pl-91 {
  padding-left: 91px !important;
}

.pr-91 {
  padding-right: 91px !important;
}

.m-91 {
  margin: 91px !important;
}

.ml-91 {
  margin-left: 91px !important;
}

.mr-91 {
  margin-right: 91px !important;
}

.mt-91 {
  margin-top: 91px !important;
}

.mb-91 {
  margin-bottom: 91px !important;
}

.f-92 {
  font-size: 92px !important;
}

.p-92 {
  padding: 92px !important;
}

.pt-92 {
  padding-top: 92px !important;
}

.pb-92 {
  padding-bottom: 92px !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.pr-92 {
  padding-right: 92px !important;
}

.m-92 {
  margin: 92px !important;
}

.ml-92 {
  margin-left: 92px !important;
}

.mr-92 {
  margin-right: 92px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.f-93 {
  font-size: 93px !important;
}

.p-93 {
  padding: 93px !important;
}

.pt-93 {
  padding-top: 93px !important;
}

.pb-93 {
  padding-bottom: 93px !important;
}

.pl-93 {
  padding-left: 93px !important;
}

.pr-93 {
  padding-right: 93px !important;
}

.m-93 {
  margin: 93px !important;
}

.ml-93 {
  margin-left: 93px !important;
}

.mr-93 {
  margin-right: 93px !important;
}

.mt-93 {
  margin-top: 93px !important;
}

.mb-93 {
  margin-bottom: 93px !important;
}

.f-94 {
  font-size: 94px !important;
}

.p-94 {
  padding: 94px !important;
}

.pt-94 {
  padding-top: 94px !important;
}

.pb-94 {
  padding-bottom: 94px !important;
}

.pl-94 {
  padding-left: 94px !important;
}

.pr-94 {
  padding-right: 94px !important;
}

.m-94 {
  margin: 94px !important;
}

.ml-94 {
  margin-left: 94px !important;
}

.mr-94 {
  margin-right: 94px !important;
}

.mt-94 {
  margin-top: 94px !important;
}

.mb-94 {
  margin-bottom: 94px !important;
}

.f-95 {
  font-size: 95px !important;
}

.p-95 {
  padding: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.m-95 {
  margin: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.f-96 {
  font-size: 96px !important;
}

.p-96 {
  padding: 96px !important;
}

.pt-96 {
  padding-top: 96px !important;
}

.pb-96 {
  padding-bottom: 96px !important;
}

.pl-96 {
  padding-left: 96px !important;
}

.pr-96 {
  padding-right: 96px !important;
}

.m-96 {
  margin: 96px !important;
}

.ml-96 {
  margin-left: 96px !important;
}

.mr-96 {
  margin-right: 96px !important;
}

.mt-96 {
  margin-top: 96px !important;
}

.mb-96 {
  margin-bottom: 96px !important;
}

.f-97 {
  font-size: 97px !important;
}

.p-97 {
  padding: 97px !important;
}

.pt-97 {
  padding-top: 97px !important;
}

.pb-97 {
  padding-bottom: 97px !important;
}

.pl-97 {
  padding-left: 97px !important;
}

.pr-97 {
  padding-right: 97px !important;
}

.m-97 {
  margin: 97px !important;
}

.ml-97 {
  margin-left: 97px !important;
}

.mr-97 {
  margin-right: 97px !important;
}

.mt-97 {
  margin-top: 97px !important;
}

.mb-97 {
  margin-bottom: 97px !important;
}

.f-98 {
  font-size: 98px !important;
}

.p-98 {
  padding: 98px !important;
}

.pt-98 {
  padding-top: 98px !important;
}

.pb-98 {
  padding-bottom: 98px !important;
}

.pl-98 {
  padding-left: 98px !important;
}

.pr-98 {
  padding-right: 98px !important;
}

.m-98 {
  margin: 98px !important;
}

.ml-98 {
  margin-left: 98px !important;
}

.mr-98 {
  margin-right: 98px !important;
}

.mt-98 {
  margin-top: 98px !important;
}

.mb-98 {
  margin-bottom: 98px !important;
}

.f-99 {
  font-size: 99px !important;
}

.p-99 {
  padding: 99px !important;
}

.pt-99 {
  padding-top: 99px !important;
}

.pb-99 {
  padding-bottom: 99px !important;
}

.pl-99 {
  padding-left: 99px !important;
}

.pr-99 {
  padding-right: 99px !important;
}

.m-99 {
  margin: 99px !important;
}

.ml-99 {
  margin-left: 99px !important;
}

.mr-99 {
  margin-right: 99px !important;
}

.mt-99 {
  margin-top: 99px !important;
}

.mb-99 {
  margin-bottom: 99px !important;
}

.f-100 {
  font-size: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.m-100 {
  margin: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.primary__card {
  border-radius: var(--borderRadius);
  padding: 10px;
  background: var(--cardBackGround);
}
.primary__card .primary__card-header {
  font-weight: bold;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  margin-bottom: -0.21425em;
  line-height: 2.285714em;
  display: block;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.primary__card .primary__card-content {
  flex-grow: 1;
  border: none;
  background: none;
  margin: 0em;
  padding: 1em 1em;
  box-shadow: none;
  font-size: 1em;
  border-radius: 0em;
}

.primary__card-white {
  background: var(--whiteColor) !important;
}

.primary__card-gradient {
  background: linear-gradient(-90deg, var(--lightColor) 0%, var(--darkColor) 100%);
  padding: 25px !important;
}

body {
  height: 100vh !important;
  padding: 0 !important;
}

body,
textarea,
input[type=text],
button {
  font-family: "Poppins" !important;
}

* {
  scrollbar-width: thin;
}

body ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 6px;
}

body ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cacfd4;
}

/*
i.inverted.green.icon {
  color: var(--lightColor) !important;
}
*/
#document_header_show {
  color: var(--darkColor) !important;
}

#document_header_show i {
  color: var(--darkColor) !important;
}

.law-font {
  font-family: "Poppins" !important;
}

.ms-button {
  background: var(--whiteColor) 0 0% no-repeat padding-box !important;
  box-shadow: 0 0 10px #67676729 !important;
  border-radius: var(--borderRadius) !important;
  font-size: 16px !important;
  padding: 10px 33px !important;
  width: -webkit-fill-available;
}

.ch-urlpath {
  color: #9094a3;
  font-weight: 300;
}

.ch-urlpath a {
  color: var(--darkColor) !important;
}

.law-form-addcustp .field {
  padding: 10px !important;
}

.law-form-addcustp label {
  font-size: 14px !important;
  color: #555d64 !important;
  font-weight: 300 !important;
}

.law-page-head {
  color: var(--darkColor) !important;
  font-weight: 400 !important;
  font-style: normal !important;
  margin-top: 10px;
  font-size: 18px !important;
}

.download-button {
  background-color: var(--darkColor);
  color: var(--whiteColor);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.subtext {
  color: #9094a3;
  font-size: 16px;
  font-weight: 400;
}

.law-radio-container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 680px) {
  .law-radio-container {
    margin-top: 10px !important;
  }
}
/* Hide the browser's default radio button */
.law-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.law-radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.law-radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.law-radio-container input:checked ~ .checkmark {
  background-color: var(--darkColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.law-radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.law-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.law-radio-container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--whiteColor);
}

/*Add Customer End */
/*Customers Page Start*/
.law-pluscust-btn {
  border-radius: 50%;
  border: none;
  outline: none;
  font-size: 28px;
  width: 40px;
  height: 40px;
  line-height: 35px;
  background-color: var(--darkColor);
  color: var(--whiteColor);
  cursor: pointer;
  float: right;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.law-usriconcuspage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.min-width-25 {
  min-width: 25%;
}

.time-preference {
  display: flex !important;
  flex-direction: row;
}

.time-preference-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  min-width: 200px;
  margin-right: 30px !important;
  border-radius: 6px;
}

.time-preference-header-text {
  color: #0a143f;
  margin: 15px 0 !important;
  font-weight: 500;
  font-size: 16px !important;
}

.save-button {
  margin: 10px !important;
}

.day-name {
  padding: 8px 12px;
  font-size: 15px;
}

.day-row {
  display: flex;
  flex-direction: row;
  margin: 5px 0 !important;
}

.uplode-icon {
  font-size: 20px !important;
  color: var(--whiteColor) !important;
}

.chatbot-image {
  min-height: 100px;
  display: flex !important;
  align-items: flex-start !important;
}

.chatbot-uplode-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chatbot-uplode-botLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  height: 100%;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.select-color {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}

.time-preference-utc-text {
  margin-top: 35px !important;
  color: #9094a3;
  font-weight: 400;
}

.records-row {
  background-color: #edf2f6;
  border-radius: var(--borderRadius);
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 5px 0 10px;
  padding: 0 15px;
  color: #9094a3;
}

.min-width-35 {
  min-width: 35% !important;
}

.min-width-30 {
  min-width: 30% !important;
}

.min-width-20 {
  min-width: 20% !important;
}

.min-width-18 {
  min-width: 18% !important;
}

.min-width-15 {
  min-width: 15% !important;
}

.min-width-220px {
  min-width: 220px;
}

.ch-cust-table-paginsn {
  border: 0 solid silver;
  padding-left: 5px;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}

.ch-cust-table-paginsn div {
  float: left;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 10px;
  margin-bottom: 15px;
}

.ch-cust-table-paginsn .ch-page-bt {
  width: 75px;
  height: 38px;
  border-radius: var(--borderRadius);
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--whiteColor);
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
}

.ch-cust-table-paginsn .ch-page-bt:last-child {
  margin-left: 15px;
}

.ch-cust-table-paginsn .ch-page-bt:hover {
  box-shadow: 0 0 7px rgba(0, 7, 36, 0.17);
}

.anchor-color {
  color: var(--darkColor);
}

.anchor-color:hover {
  color: var(--darkColor) !important;
}

.ch-cust-table-paginsn .ch-page-indx {
  width: 36px;
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
  height: 36px;
  border-radius: 22px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  font-weight: 100;
  margin-left: 15px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.ch-cust-table-paginsn .ch-page-indx:hover {
  box-shadow: 0 0 7px rgba(0, 7, 36, 0.17);
}

.ch-cust-table-paginsn .index-active {
  background-color: var(--darkColor);
  color: var(--whiteColor);
}

.blueFont {
  color: var(--darkColor) !important;
}

.button-color {
  color: var(--darkColor) !important;
  border: 1px solid var(--darkColor) !important;
  box-shadow: none !important;
  background-color: var(--whiteColor) !important;
}

.greyFont {
  color: #707070 !important;
}

.mg-top-1 {
  margin-top: 1rem !important;
  clear: both;
}

.float-right {
  float: right;
}

.textLeft {
  text-align: left !important;
}

/*shivam css end*/
.ch-card-column {
  min-width: 200px !important;
}

@media (max-width: 775px) {
  .ch-card-column {
    width: 100% !important;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--lightColor) !important;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.addText {
  text-decoration: underline;
  cursor: pointer;
}

.ch-optionaplusminus-red {
  margin-top: 8px !important;
  color: #ea5c5c;
  cursor: pointer;
}

.not-available {
  color: #ea5c5c !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/*Code For File PopUp*/
.filePopUp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(111, 128, 146, 0.7);
  display: none;
}

.file-header {
  background-color: #f6f8f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  color: var(--darkColor);
}

.file-header i {
  font-size: 20px;
}

.file-header h2 {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif !important;
  text-transform: uppercase;
}

.file-table {
  padding-left: 75px;
  font-weight: 400;
  font-size: 15px;
  width: 100%;
}

.file-table table {
  width: 100%;
  table-layout: fixed;
}

.status-button {
  padding: 10px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  border-radius: var(--borderRadius);
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid #000000;
  color: #000000;
  outline: none;
  cursor: pointer;
}

.link-button {
  padding: 10px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  border-radius: var(--borderRadius);
  border: 1px solid var(--darkColor);
  color: var(--darkColor);
  background-color: rgba(84, 150, 238, 0.05);
  outline: none;
  cursor: pointer;
}

.link-button:hover {
  color: var(--darkColor);
}

.file-main-div {
  width: 80vw;
  padding-top: 30px;
  padding-left: 75px;
  padding-right: 75px;
  background-image: url("/assets/images/headerBack.png");
  background-repeat: no-repeat;
  background-position: initial;
  background-size: 100% 700px;
  position: relative;
  z-index: 1010;
}

.back-left {
  height: 500px;
  background-image: url("/assets/images/bottomBack.png");
  width: 100%;
  transform: translateY(-250px);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position-x: -150px;
}

.back-right {
  height: 500px;
  background-image: url("/assets/images/bottomBack.png");
  width: 100%;
  transform: translateY(-250px) scaleX(-1);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position-x: -150px;
}

/*Calender Css Modification */
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--darkColor) !important;
}

.whitecolor {
  color: var(--whiteColor) !important;
}

/*Custom Radio Button*/
.custom-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheck {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  border: 1px solid #d5d7df;
}

/* On mouse-over, add a grey background color */
.custom-radio-container:hover input ~ .radioCheck {
  background-color: var(--whiteColor);
}

/* When the radio button is checked, add a blue background */
.custom-radio-container input:checked ~ .radioCheck {
  background-color: var(--whiteColor);
  border: 1px solid var(--darkColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheck:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-container input:checked ~ .radioCheck:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-container .radioCheck:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--darkColor);
}

.custom-header-time-saved {
  text-align: center;
  color: var(--darkColor) !important;
  font-weight: 400;
}

.d-flex {
  display: flex !important;
}

.color-indicator-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.color-indicator-parent span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-indicator-sub {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.isSkipped {
  background-color: rgba(215, 214, 21, 0.57);
}

.isLoop {
  background-color: rgba(117, 117, 117, 0.2) !important;
}

.isHelp {
  background-color: rgba(109, 180, 215, 0.55);
}

.isContract {
  background-color: #ecf9ee !important;
  color: var(--darkColor);
}

.isWeb {
  background-color: rgba(63, 96, 215, 0.08) !important;
}

.isBaseNet {
  background-color: #e8f0ff !important;
}

.isBoth {
  background-color: rgba(227, 253, 255, 0.56) !important;
}

.isSyneton {
  background-color: #d7f0fb !important;
  color: var(--darkColor);
}

.div-margin {
  margin-left: 20px;
}

.contract-table-heading {
  width: 99%;
  padding: 0 10px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--darkColor);
  font-weight: bold;
  flex-direction: row;
}

.records {
  display: flex;
  flex-direction: column;
  min-height: 60px;
}

.text-color {
  color: #0a143f !important;
}

.records-row:hover {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  box-shadow: 0 0 6px #0000001a;
}

.white-row {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  padding: 0;
  min-height: 40px;
  margin: 7px 0 0;
}

.white-row .custom__button-hover {
  background: var(--cardBackGround);
  border-radius: 50%;
  margin-right: 5px;
}

.white-row:hover {
  background: var(--whiteColor) 0 0% no-repeat padding-box;
  box-shadow: none;
}

.records-header {
  display: flex;
  align-items: center;
  color: var(--darkColor) !important;
  font-weight: bold;
  flex-direction: row;
}

.records-header div {
  padding-left: 5px;
}

.records-header div:last-child {
  text-align: right;
  padding-right: 5px;
}

.records-list {
  display: flex;
  color: #9094a3;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  word-break: break-word;
}

.select-button {
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(19, 29, 73, 0.25);
  padding: 10px;
  color: #131d49;
  box-shadow: 0 0 3px rgba(0, 7, 36, 0.17);
  border-radius: 10px;
}

.subtext {
  color: #9094a3;
  font-size: 16px;
  font-weight: 400;
}

.select-button-main-div {
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.min-width-15 {
  min-width: 15% !important;
}

.min-width-20 {
  min-width: 20% !important;
}

.min-width-25 {
  min-width: 25% !important;
}

.min-width-35 {
  min-width: 35% !important;
}

.min-width-45 {
  min-width: 45% !important;
}

.min-width-50 {
  min-width: 50% !important;
}

.header-style {
  color: var(--lightColor) !important;
  font-weight: 600 !important;
}

.font-style {
  color: var(--lightColor) !important;
  font-size: 12px !important;
}

.structure-of-datepicker-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contract-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  width: auto !important;
}

.color-9094A3 {
  color: var(--lightColor) !important;
}

.transition-cus {
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.custom-entity {
  background-color: var(--darkColor);
  color: var(--whiteColor);
  border-radius: 2px;
  cursor: pointer;
}

.add_entity {
  color: #3da4fe !important;
  text-align: right;
  margin-top: 5px !important;
}

.add_entity:hover {
  color: #0078d7 !important;
  text-decoration: underline;
  cursor: pointer;
}

.ngxp__container {
  background-color: var(--whiteColor);
}

.c-p, mat-icon {
  cursor: pointer;
  outline: none !important;
}

.delete-times {
  margin-left: 10px;
  color: #d76363;
}

.option-item {
  padding: 8px 0;
  cursor: pointer;
  outline: none;
  font-weight: 400;
  color: #7d7d7d;
}
.option-item span {
  margin-right: 5px;
}

.option-item:hover {
  color: var(--darkColor);
}

.ngxp__container[x-placement^=top] .ngxp__arrow {
  border-color: var(--whiteColor) transparent transparent transparent !important;
}

.ngxp__container[x-placement^=bottom] .ngxp__arrow {
  border-color: transparent transparent var(--whiteColor) transparent !important;
}

.ngxp__container[x-placement^=right] .ngxp__arrow {
  border-color: transparent var(--whiteColor) transparent transparent !important;
}

.ngxp__container[x-placement^=left] .ngxp__arrow {
  border-color: transparent transparent transparent var(--whiteColor) !important;
}

/*Common class for table*/
.law-table-header {
  display: flex;
  align-items: center;
  color: var(--darkColor) !important;
  font-weight: bold;
  flex-direction: row;
}

.law-table-header div {
  padding-left: 0;
}

.law-table-header div:last-child {
  text-align: right;
}

.law-table-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

/*Width class with step of 5*/
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  max-width: max-content;
}

.w-fit-available {
  width: -webkit-fill-available;
}

.w-1rem {
  width: 1rem;
}

/*Flex common class*/
.just-end {
  justify-content: flex-end !important;
}

.just-center {
  justify-content: center !important;
}

.just-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.ml-auto {
  margin-left: auto;
}

.action-icon {
  border-radius: 50%;
  background-color: var(--whiteColor);
  border: 2px solid #edf2f6;
  padding: 3px 4px 2px 4px;
  cursor: pointer;
}

.disabled-navigation-btn {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.disabled-not-allow {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap {
  word-wrap: break-word;
}

.color-primary {
  color: var(--darkColor);
}

.color-secondary {
  color: var(--lightColor);
}

.flex-d-c {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-inline {
  display: inline-block;
}

.border-0 {
  border: 0 !important;
}

.cursor-p {
  cursor: pointer;
}

.word-break-wrap {
  word-break: break-word;
  word-wrap: break-word;
}

.fw-bold {
  font-weight: bold;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: var(--darkColor) !important;
}

.md-drppicker .btn {
  background-color: var(--darkColor) !important;
}

.blueBackGround {
  background-color: #206dff;
}

.greenBackground {
  background-color: #38c976;
}

.orangeBackGround {
  background-color: #eb3c00 !important;
}

.saffronBackGround {
  background-color: #ff9700 !important;
}

.redBackground {
  background-color: #fe5050;
}

.yellowBackGround {
  background-color: #f5c84f;
}

.blue-back {
  background-color: var(--darkColor) !important;
}

.green-back {
  background-color: #57b457;
}

.red-back {
  background-color: #d76363 !important;
}

.green-color {
  color: #57b457;
}

.red-color {
  color: #d76363 !important;
}

.yellow-color {
  color: #f5c84f;
}

.slight__dark__gray-color {
  color: #ced0d9 !important;
}

.meta {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.ch-place-head {
  font-size: 17px;
  font-weight: 300;
  margin-left: 8px;
  color: var(--darkColor);
}

.ch-childacordActive {
  padding: 0 !important;
}

.ch-childacordActive-i {
  background-color: var(--darkColor) !important;
}

.min-w-150 {
  min-width: 150px;
}

.input-width {
  width: 225px !important;
}

.h-100 {
  height: 100%;
}

.h-fit-content {
  height: fit-content;
}

.h-40 {
  height: 38px;
}

.h-5 {
  height: 5px;
}

.h-5rem {
  height: 5rem;
}

.h-130-px {
  height: 130px;
}

.btn-stripped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 30px 30px;
}

.active-stripes.btn-stripped {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.text-center {
  text-align: center;
}

.text__transform-capitalize {
  text-transform: capitalize;
}

.primary__background {
  background-color: var(--darkColor) !important;
  color: var(--whiteColor) !important;
}

.white__background {
  background-color: var(--whiteColor) !important;
}

.card__border-radius {
  border-radius: 7px;
}

.custom__button-hover {
  border-radius: 50%;
  background: var(--whiteColor) 0 0 no-repeat padding-box;
  opacity: 1;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--darkColor) !important;
  cursor: pointer;
  border: 2px solid #edf2f6;
  outline: none;
}

.comaplateButtonColor {
  color: #38c976 !important;
}

.custom__button-disabled {
  border-radius: 50%;
  background: var(--whiteColor) 0 0 no-repeat padding-box;
  opacity: 1;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797 !important;
  cursor: pointer;
  outline: none;
  border: none;
}

.shadow__none {
  box-shadow: none;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.gap-20 {
  gap: 20px;
}

.half__opacity {
  opacity: 0.5;
}

.f-26 {
  font-size: 26px !important;
}

.longText {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom__btn {
  background-color: var(--darkColor) !important;
  width: fit-content !important;
  font-weight: 400 !important;
  color: var(--whiteColor) !important;
  cursor: pointer;
  border: none;
}

.custom__btn:hover {
  background-color: var(--darkColor) !important;
}

.custom__btn:active {
  background-color: var(--darkColor) !important;
}

.secondary__btn {
  background-color: var(--lightColor) !important;
}

.secondary__btn:hover {
  background-color: var(--lightColor) !important;
}

.secondary__btn:active {
  background-color: var(--lightColor) !important;
}

.primary-color {
  color: var(--lightColor) !important;
}

.custom__status {
  padding: 5px 15px !important;
  border-radius: 50px;
  color: var(--whiteColor);
}

.signed {
  background-color: #38c976;
}

.signed--color {
  color: #38c976;
}

.not__singed {
  background-color: #fe5050;
}

.sent_for_signing {
  background-color: #ff9700;
}

.sent_for_signing--color {
  color: #ff9700;
}

.partial__singed {
  background-color: #ff9700;
}

.partial__singed--color {
  color: #ff9700;
}

.contract__in_progress {
  background-color: #206dff;
}

.contract__in_progress__color {
  color: #206dff;
}

.error {
  background-color: #fe5050;
}

.error--color {
  color: #fe5050;
}

.status-not-sent,
.status-draft {
  background: var(--darkColor);
}

.status-sent {
  background: #7f7f7f;
}

.status-in-review,
.status-to-process {
  background: #ff9700;
}

.status-submitted,
.status-completed {
  background: #38c976;
}

.calculation-in-progress {
  background: #e68902;
}

.calculation-sent {
  background: #875204;
}

.calculation-approved {
  background: #7fdba7;
}

.calculation-rejected {
  background: #dd4b39;
}

.calculation-ready-for-filing {
  background: #00a7d3;
}

.calculation-TOW-ready {
  background: #0058aa;
}

.calculation-processed {
  background: #38c976;
}

.calculation-not-sent {
  background: var(--darkColor);
}

.calculation-done {
  background: #bf7304;
}

.calculation-processed {
  background: #38c976;
}

.calculation-locked {
  background: #ff1414;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.button, .button-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--whiteColor);
  background-color: var(--darkColor);
  padding: 10px 15px;
  font-size: 14px;
  outline: 0;
  border: 0;
  border-radius: var(--borderRadius);
  cursor: pointer;
}

button:disabled,
button[disabled],
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-secondary {
  background: var(--lightColor);
}

.circle__icon {
  background: var(--whiteColor);
  color: var(--lightColor);
  border-radius: 50%;
  padding: 5px;
}

.gap-10 {
  gap: 10px;
}

.d-none {
  display: none;
}

.slider-input {
  width: 100%;
  border: 1px solid var(--borderColor);
  font-size: 18px;
  font-weight: normal;
  border-radius: var(--borderRadius);
  padding: 15px;
  margin-bottom: 0;
  height: 80px;
  resize: none;
}

.slider {
  width: 30vw;
  min-width: 400px;
}
.slider .slider__header {
  display: flex;
  background: var(--darkColor);
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 600;
  padding: 20px 25px;
}
.slider .slider__header .header__icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.slider .slider__body {
  padding: 20px 25px;
}
.slider .slider__body .row__header {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #555d64;
  opacity: 0.5;
}
.slider .slider__body .remarks {
  margin-top: 10px;
  color: var(--darkColor);
}
.slider .slider__body .row__body {
  margin: 0;
  font-size: 16px;
  color: var(--darkColor);
}

.slider__header {
  display: flex;
  background: var(--darkColor);
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 600;
  padding: 20px 25px;
}
.slider__header .header__icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.slider__body {
  padding: 20px 25px;
}
.slider__body .row__header {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #555d64;
  opacity: 0.5;
}
.slider__body .remarks {
  margin-top: 10px;
  color: var(--darkColor);
}
.slider__body .row__body {
  margin: 0;
  font-size: 16px;
  color: var(--darkColor);
}

.no__record-found {
  width: -webkit-fill-available;
  margin-top: 20px;
  font-size: 20px;
  display: grid;
  place-items: center;
  font-weight: 600;
}

.tab__body-header {
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;
  color: var(--darkColor);
  font-size: 20px;
  font-weight: 700;
}

.w-available {
  width: -webkit-fill-available;
}

.dot {
  position: absolute;
  bottom: 1px;
  right: 10px;
  border: 1.5px solid var(--whiteColor);
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
}

.ch-uploadtext {
  font-size: 17px;
  color: #000724;
  font-weight: 300;
  display: block;
  margin-bottom: 13px;
}

.document__upload-box {
  width: 100%;
  border: 2px dashed #ced0d9;
  border-radius: 10px;
  padding: 13px 0;
  cursor: pointer;
}

.document__upload-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.document__upload-div .material-icons {
  font-size: 50px !important;
  color: #ced0d9;
}
.document__upload-div .upload-description {
  font-size: 18px;
  color: #9094a3;
}

.custom__progressbar {
  height: 12px !important;
  border-radius: 7px !important;
}

.checklistDocsOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  border-radius: var(--borderRadius);
  padding: 20px;
  background-color: gray;
  color: var(--whiteColor);
  font-size: 20px;
  line-height: 1.5;
}

.blue__dot {
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #2b6dff;
}

.orange__dot {
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #f48f01;
}

.add__custom__variable-button {
  font-size: 15px;
  margin-left: 10px !important;
  color: var(--darkColor);
}

.nlpDetails {
  width: 300px;
  background-color: var(--whiteColor);
}

.choose_image-label {
  padding: 7px;
  background: #6f8092;
  color: var(--whiteColor);
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
}

.text-muted {
  opacity: 0.5;
  font-size: 10px;
}

.iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}

.w-auto {
  width: auto !important;
}

.ui.dimmer {
  z-index: 1001 !important;
}

.ng2-dropdown-menu div {
  z-index: 100 !important;
}

.align-start {
  align-self: flex-start;
}

.field-required:after {
  content: "*";
  color: red;
}

#finalConfirmation {
  left: 0;
}
#finalConfirmation .custom__btn {
  background-color: var(--button-background) !important;
  color: var(--button-text) !important;
  outline: 0 !important;
  border: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
}

.reviewModal .p-dialog-content {
  overflow: visible;
}

.email__colors-div {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 10px !important;
  border-radius: var(--borderRadius);
}

.overflow-auto {
  overflow: auto;
}

.smile, .close {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.card__divider {
  border-top: 1px solid #b0b3bf;
  width: 37px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.email__colors-div {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 10px !important;
  border-radius: var(--borderRadius);
}

.content-separator {
  height: 1px;
  background-color: var(--borderColor);
}

.note-card {
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  padding: 10px;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: var(--whiteColor);
}

.flex-none {
  flex: none !important;
}

.content-separator {
  height: 1px;
  background-color: var(--borderColor);
}

.note-card {
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  padding: 10px;
}

.additional__attachments-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--darkColor);
  border-radius: var(--borderRadius);
  cursor: pointer;
  padding: 5px;
  max-width: 200px;
}

ngx-extended-pdf-viewer button:focus, ngx-extended-pdf-viewer a:focus, ngx-extended-pdf-viewer input:focus, ngx-extended-pdf-viewer select:focus {
  border: 1px solid var(--darkColor) !important;
}

.custom__modal .ui .modal {
  right: 20%;
  margin: 75px auto 0 auto !important;
  width: calc(75% - 240px);
  height: calc(100vh - 120px);
  overflow: auto;
  background: var(--borderColor);
  border-radius: 10px !important;
}
.custom__modal .ui .modal .header {
  background: var(--borderColor);
}
.custom__modal .ui .modal .content {
  background: var(--borderColor);
}
.custom__modal .mat-calendar-body-selected {
  background-color: var(--user-background) !important;
  color: var(--user-text) !important;
}
.custom__modal .mat-datepicker-toggle-default-icon {
  color: var(--user-background) !important;
}
.custom__modal .mat-datepicker-popup {
  border-radius: 10px;
  margin-bottom: 20px;
}
.custom__modal .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}
.custom__modal .mat-form-field-wrapper {
  padding: 0 !important;
}
.custom__modal .mat-form-field-infix {
  border: 0 !important;
}
.custom__modal .mat-datepicker-toggle-default-icon {
  outline: none;
  height: 24px !important;
  width: 24px !important;
}
.custom__modal .pac-container {
  margin-top: -200px;
}

mat-tooltip-component .mat-tooltip {
  background: var(--whiteColor);
  color: #1e1e1e;
  font-size: 12px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

app-common-chips .p-autocomplete .p-autocomplete-multiple-container, app-common-chips .p-autocomplete .p-chips-multiple-container, app-common-chips .p-component .p-autocomplete-multiple-container, app-common-chips .p-component .p-chips-multiple-container, app-entity .p-autocomplete .p-autocomplete-multiple-container, app-entity .p-autocomplete .p-chips-multiple-container, app-entity .p-component .p-autocomplete-multiple-container, app-entity .p-component .p-chips-multiple-container {
  width: 100%;
}
app-common-chips .p-autocomplete .p-autocomplete-multiple-container:hover, app-common-chips .p-autocomplete .p-autocomplete-multiple-container:focus, app-common-chips .p-autocomplete .p-autocomplete-multiple-container:focus-within, app-common-chips .p-autocomplete .p-chips-multiple-container:hover, app-common-chips .p-autocomplete .p-chips-multiple-container:focus, app-common-chips .p-autocomplete .p-chips-multiple-container:focus-within, app-common-chips .p-component .p-autocomplete-multiple-container:hover, app-common-chips .p-component .p-autocomplete-multiple-container:focus, app-common-chips .p-component .p-autocomplete-multiple-container:focus-within, app-common-chips .p-component .p-chips-multiple-container:hover, app-common-chips .p-component .p-chips-multiple-container:focus, app-common-chips .p-component .p-chips-multiple-container:focus-within, app-entity .p-autocomplete .p-autocomplete-multiple-container:hover, app-entity .p-autocomplete .p-autocomplete-multiple-container:focus, app-entity .p-autocomplete .p-autocomplete-multiple-container:focus-within, app-entity .p-autocomplete .p-chips-multiple-container:hover, app-entity .p-autocomplete .p-chips-multiple-container:focus, app-entity .p-autocomplete .p-chips-multiple-container:focus-within, app-entity .p-component .p-autocomplete-multiple-container:hover, app-entity .p-component .p-autocomplete-multiple-container:focus, app-entity .p-component .p-autocomplete-multiple-container:focus-within, app-entity .p-component .p-chips-multiple-container:hover, app-entity .p-component .p-chips-multiple-container:focus, app-entity .p-component .p-chips-multiple-container:focus-within {
  border-color: #ced4da !important;
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
}
app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token, app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token, app-common-chips .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token, app-common-chips .p-autocomplete .p-chips-multiple-container .p-chips-input-token, app-common-chips .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token, app-common-chips .p-component .p-autocomplete-multiple-container .p-chips-input-token, app-common-chips .p-component .p-chips-multiple-container .p-autocomplete-input-token, app-common-chips .p-component .p-chips-multiple-container .p-chips-input-token, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token, app-entity .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token, app-entity .p-autocomplete .p-chips-multiple-container .p-chips-input-token, app-entity .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token, app-entity .p-component .p-autocomplete-multiple-container .p-chips-input-token, app-entity .p-component .p-chips-multiple-container .p-autocomplete-input-token, app-entity .p-component .p-chips-multiple-container .p-chips-input-token {
  padding: 0 !important;
}
app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input, app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token input, app-common-chips .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token input, app-common-chips .p-autocomplete .p-chips-multiple-container .p-chips-input-token input, app-common-chips .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token input, app-common-chips .p-component .p-autocomplete-multiple-container .p-chips-input-token input, app-common-chips .p-component .p-chips-multiple-container .p-autocomplete-input-token input, app-common-chips .p-component .p-chips-multiple-container .p-chips-input-token input, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token input, app-entity .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token input, app-entity .p-autocomplete .p-chips-multiple-container .p-chips-input-token input, app-entity .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token input, app-entity .p-component .p-autocomplete-multiple-container .p-chips-input-token input, app-entity .p-component .p-chips-multiple-container .p-autocomplete-input-token input, app-entity .p-component .p-chips-multiple-container .p-chips-input-token input {
  border: 0 !important;
  padding: 10px !important;
}
app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input:focus, app-common-chips .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token input:focus, app-common-chips .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token input:focus, app-common-chips .p-autocomplete .p-chips-multiple-container .p-chips-input-token input:focus, app-common-chips .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token input:focus, app-common-chips .p-component .p-autocomplete-multiple-container .p-chips-input-token input:focus, app-common-chips .p-component .p-chips-multiple-container .p-autocomplete-input-token input:focus, app-common-chips .p-component .p-chips-multiple-container .p-chips-input-token input:focus, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input:focus, app-entity .p-autocomplete .p-autocomplete-multiple-container .p-chips-input-token input:focus, app-entity .p-autocomplete .p-chips-multiple-container .p-autocomplete-input-token input:focus, app-entity .p-autocomplete .p-chips-multiple-container .p-chips-input-token input:focus, app-entity .p-component .p-autocomplete-multiple-container .p-autocomplete-input-token input:focus, app-entity .p-component .p-autocomplete-multiple-container .p-chips-input-token input:focus, app-entity .p-component .p-chips-multiple-container .p-autocomplete-input-token input:focus, app-entity .p-component .p-chips-multiple-container .p-chips-input-token input:focus {
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
}

.p-progressbar__height .p-progressbar {
  height: 12px;
}

.p-progressbar__radius .p-progressbar {
  border-radius: var(--borderRadius);
}

.p-progressbar-value__radius.p-progressbar .p-progressbar-value {
  border-radius: var(--borderRadius);
}

.p-progressbar__background .p-progressbar {
  background-color: #b3c1c3;
}

.p-progressbar__dark.p-progressbar .p-progressbar-value {
  background: var(--darkColor);
}

.p-progressbar__30.p-progressbar .p-progressbar-value {
  background: #d95c5c;
}

.p-progressbar__50.p-progressbar .p-progressbar-value {
  background: #E6BB48;
}

.p-progressbar__80.p-progressbar .p-progressbar-value {
  background: #b4d95c;
}

.p-progressbar__100.p-progressbar .p-progressbar-value {
  background: #66da81;
}

.ready-to-submit-progressbar__color.p-progressbar .p-progressbar-value {
  background: var(--darkColor);
}