.p-speeddial {
  position: initial !important;
}

.p-speeddial-button {
  &.p-button {
    &.p-button-icon-only {
      width: 3rem;
      height: 3rem;
      background: var(--lightColor);
      border-color: var(--lightColor);
      &:hover {
        background: var(--lightColor);
        border-color: var(--lightColor);
      }
    }
  }
}
.p-speeddial-action {
  background: var(--darkColor);
  color:var(--whiteColor);
  &:hover {
    background: var(--darkColor);
    color:var(--whiteColor);
  }
}
