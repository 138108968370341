// START INPUT SWITCH OVERRIDE SCSS
.p-inputswitch {
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: var(--darkColor);
    }
    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: var(--darkColor);
      }
    }
  }
  &.p-focus {
    .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2rem #5b8d97;
    }
  }
}
// END INPUT SWITCH OVERRIDE SCSS


// START INPUT TEXT OVERRIDE SCSS
.p-inputtext {
  &:enabled {
    &:hover {
      border-color: var(--darkColor);
    }
    &:focus {
      border-color: var(--lightColor);
      box-shadow: none;
    }
  }
}
// END INPUT TEXT OVERRIDE SCSS

// START RADIO BUTTON OVERRIDE SCSS
.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: var(--darkColor);
    }
    &.p-highlight {
      background: var(--darkColor);
      border-color: var(--darkColor);
      &:not(.p-disabled):hover {
        background: var(--darkColor);
        border-color: var(--darkColor);
      }
    }
  }
}
// END RADIO BUTTON OVERRIDE SCSS
